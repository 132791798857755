import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useYourPools from '../hooks/useYourPools';

const PoolInfo = ({ token, ...props }) => {
  const { t } = useTranslation();
  const { tableData } = useYourPools();

  const matchedPool = tableData.find(pool => pool.token.id === token.id);

  return (
    <div {...props}>
      <p className="text-[14px] font-medium text-primary-fuscia">{t('pool.operation.poolInfo')}</p>
      <div className='mt-[8px] flex py-[40px] px-[24px] justify-between rounded-[12px] bg-white'>
        <div className='flex flex-col gap-[36px]'>
          <p className='text-[14px] font-medium text-G-500'>{t('pool.operation.pooledTokens')}</p>
          <p className='font-medium'>{matchedPool?.displayPeggedAmount || '-'}</p>
        </div>
        <div className='flex flex-col gap-[36px]'>
          <p className='text-[14px] font-medium text-G-500'>{t('pool.operation.lpt')}</p>
          <p className='font-medium'>{matchedPool?.displayPeggedAmount || '-'}</p>
        </div>
        <div className='flex flex-col gap-[36px]'>
          <p className='text-[14px] font-medium text-G-500'>{t('pool.operation.share')}</p>
          <p className='font-medium'>{matchedPool?.displayPoolShare || '-'}</p>
        </div>
        <div className='flex flex-col gap-[36px]'>
          <p className='text-[14px] font-medium text-G-500'>{t('pool.operation.volume24')}</p>
          <p className='font-medium'>{matchedPool?.displayOneDayVolume || '-'}</p>
        </div>
        <div className='flex flex-col gap-[36px]'>
          <p className='text-[14px] font-medium text-G-500'>{t('pool.operation.liquidity')}</p>
          <p className='font-medium'>{matchedPool?.displayLiquidity || '-'}</p>
        </div>
      </div>
    </div>
  );
}

PoolInfo.propTypes = {
  token: PropTypes.object.isRequired,
};

export default PoolInfo;
