import { useEffect, useState } from "react";

import { ChainConfig, KeyOfCfx } from "../constants/chainConfig";
import { checkCfxTokenAddress, checkHexAddress } from "../utils/address";
import { requestZGTokenList, requestAllTokenList } from "../utils/api";
import { tokenContractsInfo, findChainConfigById, ProxyUrlPrefix } from "../constants";

class GetTokenListImplentation {
  fetch() {}
  getTokenList() {}
}

class GetTokenListFromZG extends GetTokenListImplentation {
  constructor() {
    super();
    this.list = [];
  }

  getTokenInfo(key) {
    const info = tokenContractsInfo[Object.keys(tokenContractsInfo).find(k => k.toLowerCase() === key.toLowerCase())];
    if (!info) return {
      symbol: '',
      name: '',
    };
    return info;
  }

  applyChainInfo(token) {
    const chainInfo = findChainConfigById(token.chain_id)
    return {
      ...token,
      ...chainInfo,
      chainName: chainInfo?.fullName,
    }
  }

  async fetch() {
    if (this.list.length) return;
    const rawData = await requestZGTokenList() || [];
    const tokenInfo = rawData.map(async token => {
      if (findChainConfigById(token.chain_id).nativeToken === token.token_address)
        return {
          symbol: token.token_abbr,
          name: token.token_abbr,
        }
      const tokenContractKey = `${token.chain_id}+${token.token_address}`;
      try {
        const { symbol, name } = await this.getTokenInfo(tokenContractKey);
        return {
          symbol,
          name,
        }
      } catch (err) {
        console.log(
          `token ${token.token_abbr} on chain ${
            findChainConfigById(token.chain_id).chainName
          } error ${err}`,
        )
      }
    })
    const data = await Promise.all(tokenInfo).then(tokenInfo => {
      return rawData.map(this.applyChainInfo).map((token, index) => {
        return {
          ...token,
          ...tokenInfo[index],
          id: `${token.chain_id}+${token.token_address}`,
        }
      })
    })
    this.list = data;
  }
  getTokenList() {
    return this.list;
  }
}

class GetTokenListFromSF extends GetTokenListImplentation {
  constructor() {
    super();
    this.list = [];
  }

  transformNativeTokenAddress(address) {
    if (!checkCfxTokenAddress(address) && !checkHexAddress(address))
      return "0x0000000000000000000000000000000000000001";
    return address;
  }

  mapSFTokenDataToZG(sfToken) {
		const isFromCfx = sfToken.origin === "cfx";
		const tokenAbbr = String(
			isFromCfx ? sfToken.symbol : sfToken.reference_symbol,
		).toUpperCase();
    const toTokenAddress = this.transformNativeTokenAddress(
      sfToken.reference,
    )

		return [
			{
				...sfToken,
				token_address:
					sfToken.ctoken === KeyOfCfx
						? "0x0000000000000000000000000000000000000001"
						: sfToken.ctoken,
				chainName: ChainConfig[KeyOfCfx]?.fullName,
				token_abbr: tokenAbbr,
				chain_id: ChainConfig[KeyOfCfx].id,
				toChains: [sfToken.to_chain],
				isSF: true,
				symbol: sfToken.symbol,
				sfChain: KeyOfCfx,
				display_symbol: sfToken.symbol,
				display_name: sfToken.name,
				address: sfToken.ctoken, // address may be string, such as 'eth', 'cfx'
        cfxDecimals: sfToken.ctoken === KeyOfCfx ? sfToken.decimals : 18, // decimals of tokens which are not cfx origin is 18
			},
			{
				...sfToken,
				token_address: toTokenAddress,
				chainName:
					ChainConfig[isFromCfx ? sfToken.to_chain : sfToken.origin]?.fullName,
				token_abbr: tokenAbbr,
				chain_id: ChainConfig[isFromCfx ? sfToken.to_chain : sfToken.origin].id,
				isSF: true,
				symbol: sfToken.reference_symbol,
				sfChain: isFromCfx ? sfToken.to_chain : sfToken.origin,
				display_symbol: sfToken.reference_symbol,
				display_name: sfToken.reference_name,
				address: sfToken.reference, // address may be string, such as 'eth', 'cfx'
			},
		].map((token) => {
			token.id = `${token.sfChain || token.chainName}+${token.token_abbr}`; // sf token should use sfChain for id
			return token;
		});
	};

  mapSFTokenListToZG(sfTokenList) {
		const mappedData = sfTokenList
			.filter((token) => {
				if (token.origin === "btc" || token.to_chain === "btc") return true;
				return token?.supported === 1 && token?.in_token_list === 1;
			})
			.flatMap(this.mapSFTokenDataToZG.bind(this));
		const tokenAddressSet = new Set(
			mappedData.map(
				(token) => `${token?.chain_id}+${token?.token_abbr.toUpperCase()}`,
			),
		);
		const uniqueTokenAddressList = [...tokenAddressSet];
		const uniqueTokenList = uniqueTokenAddressList.map((tokenAddress) => {
			const tokenData = mappedData.find(
				(token) =>
					`${token?.chain_id}+${token?.token_abbr.toUpperCase()}` ===
					tokenAddress,
			);
			return tokenData;
		});
		return uniqueTokenList;
	}

  async fetch() {
    if (this.list.length) return;
    const rawData = await requestAllTokenList(ProxyUrlPrefix.sponsor) || [];
    this.list = rawData;
  }

  getTokenList() {
    return this.mapSFTokenListToZG(this.list);
  }
}

export const zgTokenListImpl = new GetTokenListFromZG();
export const sfTokenListImpl = new GetTokenListFromSF();

/**
 * combine zg and sf token list
 * CAUTION: this list is for display only, it includes all the useable tokens
 */
export const useAllTokenList = () => {
  const [zgTokenList, setZgTokenList] = useState([]);
  const [sfTokenList, setSfTokenList] = useState([]);

  const isV2 = process.env.REACT_APP_ENABLE_V2 === 'true'

  useEffect(() => {
    if (isV2) {
      zgTokenListImpl.fetch().then(() => {
        setZgTokenList(zgTokenListImpl.getTokenList());
      });
    } else {
      setZgTokenList([])
    }
    // setZgTokenList(zgTokenListImpl.getTokenList());
    sfTokenListImpl.fetch().then(() => {
      setSfTokenList(sfTokenListImpl.getTokenList());
    });
  }, [])

	return [...zgTokenList, ...sfTokenList];
};
