import { JsonRpcProvider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { WeiPerEther } from "@ethersproject/constants";

import { tokenContractsInfo } from "../../../constants/tokenConfig";
import { ChainConfig, ContractConfig, ContractType } from "../../../constants";
import { ERC20_ABI, ZGBridge_ABI } from "../../../abi";
import { getWeb3Balance } from "../../../hooks/useZGBalance";
import { requestVolumeSummary } from "../../../utils/api";
import { supportedPools } from "../../../constants";

class PoolListManager {
  constructor(pools) {
    this.pools = pools;
    this.data = [];
    this.price = {};
  }

  async fetchPrice() {
    if (Object.keys(this.price).length) return;
    const priceSymbolList = new Set(this.pools.map(pool => {
      const tokenContractInfo = tokenContractsInfo[pool];
      return tokenContractInfo.priceName;
    }).filter(name => !!name));
    const raw = await fetch(`/api/price`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await raw.json();
    return Promise.all(Array.from(priceSymbolList).map(async priceName => {
      try {
        data.forEach(item => {
          if (priceName === item.symbol) {
            this.price[priceName] = Number(item.price);
          }
        });
      } catch (err) {
        this.price[priceName] = 0;
        console.log(err);
      }
    }));
  }

  async fetch(account, tokenList, force = false) {
    if (this.data.length && !force) return this.data;
    await this.fetchPrice();
    const summary = await requestVolumeSummary('/rpcnext');
    const pools = await Promise.all(this.pools.map(async (pool) => {
      const [chainId, address] = pool.split('+');
      const { symbol, name, priceName } = tokenContractsInfo[pool];
      const chain = Object.values(ChainConfig).find(chain => chain.id === chainId);
      const providerUrl = chain.rpcUrl;
      const provider = new JsonRpcProvider(providerUrl);
      const bridgeContract = ContractConfig[ContractType.bridge].address[chain.key]
      const contract = new Contract(bridgeContract, ZGBridge_ABI, provider);
      const peggedTokenAddress = await contract.backToPegged(address);
      const peggedTokenContract = new Contract(peggedTokenAddress, ERC20_ABI, provider);
      const matchedToken = tokenList.find(t => t.chain_id === chainId && t.token_address === String(address).toLocaleLowerCase());
      if (!matchedToken) return null;
      const { decimals, token_abbr } = matchedToken;
      const price = this.price[priceName];
      const liquidityInToken = await peggedTokenContract.totalSupply();
      const liquidity = liquidityInToken.mul(parseUnits(String(price))).div(WeiPerEther);
      const tokenBalance = formatUnits(await getWeb3Balance(provider, account, address, chainId), decimals);
      let oneDayVolume = 0;
      let sevenDayVolume = 0;
      try {
        oneDayVolume = parseUnits(String(summary['1d'][token_abbr]?.[String(chainId)] || 0)).mul(parseUnits(String(price))).div(WeiPerEther);
        sevenDayVolume = parseUnits(String(summary['7d'][token_abbr]?.[String(chainId)] || 0)).mul(parseUnits(String(price))).div(WeiPerEther);
      } catch (err) {
        console.log(err);
      }

      return {
        id: pool,
        chainId,
        address,
        symbol,
        token_abbr,
        name,
        liquidity,
        peggedTokenAddress,
        tokenBalance,
        token: matchedToken,
        price,
        decimals,
        provider,
        liquidityInToken,
        oneDayVolume,
        sevenDayVolume,
      };
    }));

    this.data = pools.filter(pool => !!pool);
    return pools;
  }

  getPoolTableData() {
    return this.data.map((pool) => {
      return {
        ...pool,
        displayLiquidity: `$${formatUnits(pool.liquidity, pool.decimals).toString()}`,
        displayOneDayVolume: `$${formatUnits(pool.oneDayVolume, pool.decimals).toString()}`,
        displaySevenDayVolume: `$${formatUnits(pool.sevenDayVolume, pool.decimals).toString()}`,
      };
    });
  }

  getTVL() {
    return this.data.reduce((acc, pool) => {
      return acc + Number(formatUnits(pool.liquidity, pool.decimals));
    }, 0);
  }
}

const poolList = new PoolListManager(supportedPools);

export default poolList;
