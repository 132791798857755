import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Tabs } from "../../components";

import AllPools from "./AllPools";
import YourPools from "./YourPools";
import useAllPools from "./hooks/useAllPools";

const PoolList = () => {
  const {t} = useTranslation();
  const [tab, setTab] = useState('allPools');
  const {tvl} = useAllPools();

  const handleSwitchTab = (tab) => {
    setTab(tab.value);
  }

  return (
    <div>
      <header className="flex items-center space-between">
        <div className="flex gap-[32px] mb-[24px] items-center">
          <Tabs
            tabs={[
              {
                label: t('pool.list.allPools'),
                value: 'allPools'
              },
              {
                label: t('pool.list.yourPools'),
                value: 'yourPools'
              }
            ]}
            onSelect={handleSwitchTab}
          />
          <p>TVL: {tvl}$</p>
        </div>
      </header>
      {tab === 'allPools' && <AllPools />}
      {tab === 'yourPools' && <YourPools />}
    </div>
  );
}

export default PoolList;
