import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types';

import PoolList from './PoolList';
import { KeyOfPortal } from '../../constants';
import { useEffect } from 'react';

const Pool = ({ setHideWalletList }) => {
  const {t} = useTranslation()

  useEffect(() => {
    setHideWalletList([KeyOfPortal]);
  }, [setHideWalletList])

  return (
    <main className="flex flex-col w-full mx-[32px] md:w-[704px] lg:w-[944px] xl:w-[1008px] pt-0 overflow-y-auto pt-12">
      <div className='mb-[36px]'>
        <p className='text-G-900 text-2lg font-semibold mb-[16px]'>{t('pool.title')}</p>
        {/* <p className='text-G-600 text-sm font-medium'>{t('pool.description')}</p> */}
      </div>
      <PoolList />
    </main>
  );
}

Pool.propTypes = {
  setHideWalletList: PropTypes.func.isRequired,
};

export default Pool;
