import { Contract } from "@ethersproject/contracts";
import { formatUnits } from "@ethersproject/units";

import { tokenContractsInfo } from "../../../constants";
import poolList from "./poolList";
import { ERC20_ABI } from "../../../abi";

class YourPools {
  constructor(poolList) {
    this.poolList = poolList;
    this.data = [];
  }

  async fetch(account, tokenList, force = false) {
    if (this.data.length && !force) return this.data;
    await this.poolList.fetch(account, tokenList, force);
    const data = await Promise.all(this.poolList.getPoolTableData().map(async pool => {
      const peggedToken = tokenContractsInfo[pool.id].pegged;
      const contract = new Contract(peggedToken, ERC20_ABI, pool.provider);
      const peggedAmount = await contract.balanceOf(account);
      if (peggedAmount.isZero()) return {
        pool,
        peggedAmount: 0,
        poolShare: 0,
      };
      const poolShare = Number(formatUnits(peggedAmount, pool.decimals)) / Number(formatUnits(pool.liquidityInToken, pool.decimals));

      return {
        pool,
        peggedAmount,
        poolShare,
      };
    }));

    this.data = data;
    return data;
  }

  getPoolTableData() {
    return this.data.map((pool) => {
      return {
        ...pool.pool,
        displayLiquidity: `$${(Number(formatUnits(pool.pool.liquidity, pool.pool.decimals).toString())).toFixed(4)}`,
        peggedAmount: pool.peggedAmount,
        poolShare: pool.poolShare,
        displayPeggedAmount: `${Number(formatUnits(pool.peggedAmount, pool.pool.decimals).toString()).toFixed(6)}`,
        displayPoolShare: `${pool.poolShare ? (pool.poolShare * 100).toFixed(3) : '0'}%`,
      };
    });
  }
}

const yourPools = new YourPools(poolList);

export default yourPools;
