import {Conflux} from 'js-conflux-sdk/dist/js-conflux-sdk.umd.min.js'

let cfx
if (window?.confluxJS?.version !== '2.0.3') {
  cfx = new Conflux({
    url: 'https://main.confluxrpc.com',
    defaultGasPrice: 1000000,
    defaultGas: 1000000,
    logger: console,
  })

  window.confluxJS = cfx
  const timer = setInterval(() => {
    if (window.conflux) {
      window.confluxJS.provider = window.conflux
      clearInterval(timer)
    }
  }, 1000)
}

export default cfx
