import {useEffect, useState, useMemo, useCallback, forwardRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Contract} from '@ethersproject/contracts'
import {parseUnits, formatUnits} from '@ethersproject/units'
import {MaxUint256} from '@ethersproject/constants'
// import { BigNumber } from '@ethersproject/bignumber'
import {debounce} from 'underscore'
import Big from 'big.js'
import PropTypes from 'prop-types'

import {useZGFormState} from '../../../state/zgForm'
import {Button} from '../../../components'
import {
  ContractConfig,
  ContractType,
  findChainKeyById,
  ChainConfig,
  KeyOfCfx,
  TypeTransaction,
  TransactionStatus,
  ProxyUrlPrefix,
  KeyOfBtc,
} from '../../../constants'
import {ERC20_ABI, Pool_ABI, ZGBridge_ABI} from '../../../abi'
import {useAllTokenList} from '../../../hooks/useAllTokenList'
import useShuttleOut from '../../../hooks/useShuttleOut'
import {useShuttleFee, useCustodianData} from '../../../hooks/useShuttleData'
import useShuttleIn from '../../../hooks/useShuttleIn'
import { calculateGasMargin } from '../../../utils'
import { KeyOfPortal, KeyOfMetaMask } from '../../../constants'
import { useIsFromChainBtc, useIsToChainBtc, useIsFromChainCfx } from '../../../state/zgForm'
import ToBtcAddress from './ToBtcAddress'
import { useTxData } from '../../../hooks/useTransaction'
import { isNumber } from '../../../utils'
import { useZGBalance } from '../../../hooks/useZGBalance'
import { requestUserWallet } from '../../../utils/api'
import { Notification } from '../../../components'
import { useWallet } from '../../../hooks/useZGWallet'
import switchWeb3Network from '../../../utils/switchWeb3Network'

import SVGZGFlip from '../../../assets/svg/ZGFlip'

import GroupBox from './GroupBox'
import TransactionModal from './TransactionModal'
import BTCTransactionModal from './BTCTransactionModal'
import PendingTxList from './PendingTxList'
import FromWalletModal from './FromWalletModal'
import ToWalletModal from './ToWalletModal'
import { checkBtcAddress, checkCfxTokenAddress, checkHexAddress } from '../../../utils/address'
// import { useFeeData } from 'wagmi'

const Form = forwardRef(function Form ({ className, onClick }, ref) {
  const {
    fromToken,
    toToken,
    setFromToken,
    setToToken,
    fromAmount,
    lastWeb3Wallet,
    setFromAmount,
    toAmount,
    setToAmount,
    toAddress: manualToAddress,
    setFee,
    fee,
    sameWallet,
    setFromWallet,
    setToWallet,
    fromWallet,
    toWallet,
    setToBtcAddress,
    toBtcAddress,
    defaultSetLock,
    setDefaultSetLock,
    setSameWallet,
    setTokenFee,
  } = useZGFormState()

  const fromAccount = useWallet(fromWallet)
  const toAccount = useWallet(toWallet)
  const { address: fromAddress, web3Provider, chainId } = fromAccount
  const { address: toAddress } = toAccount

  const fromTokenKey = findChainKeyById(fromToken.chain_id)
  const [showTransactionModal, setShowTransactionModal] = useState()
  const [transactionStatus, setTransactionStatus] = useState('waiting')
  const {t} = useTranslation()
  const allTokenList = useAllTokenList()
  const [showFromWalletModal, setShowFromWalletModal] = useState(false)
  const [showToWalletModal, setShowToWalletModal] = useState(false)
  const shuttleOut = useShuttleOut({
    fromChain: fromToken.sfChain,
    toChain: toToken.sfChain,
    fromToken,
    toToken,
    value: fromAmount,
    fromAddress: fromAddress,
    toAddress: manualToAddress || toAddress,
    setSendStatus: setTransactionStatus,
  })
  const shuttleIn = useShuttleIn({
    fromChain: fromToken.sfChain,
    toChain: toToken.sfChain,
    fromToken,
    toToken,
    value: fromAmount,
    fromAddress: fromAddress,
    toAddress: manualToAddress || toAddress,
    fromWallet,
    setSendStatus: setTransactionStatus,
  })
  const isFromCfx = useIsFromChainCfx();
  const shuttleFee = useShuttleFee(
    isFromCfx ? toToken.sfChain : fromToken.sfChain,
    fromToken,
    toToken.sfChain,
    fromAmount,
  )
  const [showBTCModal, setShowBTCModal] = useState(false);
  const isFromChainBtc = useIsFromChainBtc();
  const isToChainBtc = useIsToChainBtc();
  const pendingHistory = useTxData([TransactionStatus.pending, TransactionStatus.waiting], [TypeTransaction.transaction]);
  const successHistory = useTxData([TransactionStatus.success], [TypeTransaction.transaction]);
  const custodianContractChain = isFromCfx ? toToken.sfChain : fromToken.sfChain;
  const { minimal_in_value, minimal_out_value } = useCustodianData(custodianContractChain, toToken);
  let tokenDecimal = fromToken.decimals;
  if (isFromCfx && fromToken.origin !== KeyOfCfx) tokenDecimal = 18;
  const { balance, displayBalance } = useZGBalance(
    fromWallet,
    fromToken.chain_id,
    fromToken.token_address,
    tokenDecimal,
  );

  const handleClickFlip = () => {
    // flip fromToken and toToken
    setFromToken(toToken)
    setToToken(fromToken)
    // flip fromWallet and toWallet
    if (!sameWallet) {
      setFromWallet(toWallet)
      setToWallet(fromWallet)
    }
  }

  const handleZGTransaction = async () => {
    const isNativeToken =
      ChainConfig[fromTokenKey]?.nativeToken ===
      fromToken.token_address

    const bridgeContract = ContractConfig[ContractType.bridge].address[fromTokenKey]
    const contract = new Contract(
      bridgeContract,
      ZGBridge_ABI,
      web3Provider.getSigner(),
    )
    const amountBigNumber = parseUnits(fromAmount, fromToken.decimals)
    const toAddress = manualToAddress || toAccount.address;
    try {
      const gas = await contract.estimateGas.deposit(
        fromToken.token_address,
        amountBigNumber.toString(),
        toToken.chain_id,
        toAddress,
        new Date().getTime(),
        {
          value: isNativeToken
            ? amountBigNumber.add(fee).toString()
            : fee.toString(),
        },
      )
      const gasLimit = gas.add(gas.div(10))
      const tx = await contract.deposit(
        fromToken.token_address,
        amountBigNumber.toString(),
        toToken.chain_id,
        toAddress,
        new Date().getTime(),
        {
          value: isNativeToken
            ? amountBigNumber.add(gasLimit).add(fee).toString()
            : gasLimit.add(fee).toString(),
          gasLimit,
        },
      )
      await tx.wait();
      setTransactionStatus('success')
    } catch (err) {
      console.log(err)
      setTransactionStatus('failed')
    }
  }

  const getShuttleInWalletAddress = async() => {
    const data = await requestUserWallet([
      ProxyUrlPrefix.shuttleflow,
      manualToAddress || toAddress,
      undefined,
      toToken.sfChain,
      fromToken.sfChain,
      'in'
    ]);
    return data;
  }

  const handleStartTransaction = async () => {
    if (isFromChainBtc) {
      setShowBTCModal(true);
      return;
    };
    if (fromToken.sfChain === KeyOfCfx) {
      const checkRes = checkCfxTokenAddress(fromAddress, 'user');
      if (!checkRes) {
        Notification.open({
          type: 'error',
          content: t('zgPortal.form.invalidFromAddress'),
        })
        return;
      }
    } else {
      const checkRes = checkHexAddress(fromAddress);
      if (!checkRes) {
        Notification.open({
          type: 'error',
          content: t('zgPortal.form.invalidFromAddress'),
        })
        return;
      }
    }
    if (toToken.sfChain === KeyOfCfx) {
      const checkRes = checkCfxTokenAddress(manualToAddress || toAddress, 'user');
      if (!checkRes) {
        Notification.open({
          type: 'error',
          content: t('zgPortal.form.invalidToAddress'),
        })
        return;
      }
    } else {
      const checkRes = checkHexAddress(manualToAddress || toAddress);
      if (!checkRes) {
        Notification.open({
          type: 'error',
          content: t('zgPortal.form.invalidToAddress'),
        })
        return;
      }
    }
    setTransactionStatus('waiting')
    setShowTransactionModal(true)

    const isNativeToken =
      ChainConfig[fromTokenKey]?.nativeToken ===
      fromToken.token_address
    if (!isNativeToken) {
      await approveToken()
    }

    if (fromToken.isSF || toToken.isSF) {
      if (fromToken.sfChain === 'cfx') {
        await shuttleOut()
      } else {
        const walletAddress = await getShuttleInWalletAddress();
        await shuttleIn(walletAddress)
      }
    } else {
      setTimeout(async () => {
        await handleZGTransaction()
      }, 2000)
    }
  }

  const isChainSameAsFromToken = useMemo(() => {
    if (!fromToken.chain_id) return true;
    return fromToken.chain_id === String(fromAccount.chainId)
  }, [fromToken, fromAccount.chainId])

  const handleSwitchNetwork = async () => {
    if (isChainSameAsFromToken) return
    if (!fromToken?.chain_id) return
    if (fromToken?.sfChain === KeyOfCfx) {
      await window?.confluxJS.request({ method: 'wallet_switchConfluxChain', params: [{ chainId: `0x${fromToken.chain_id}` }] });
      return;
    }
    await switchWeb3Network(fromWallet, fromToken, fromTokenKey);
  }

  const fromTokenList = allTokenList

  const toTokenList = useMemo(() => {
    return allTokenList.filter(token => {
      const baseRule =
        token.chain_id !== fromToken.chain_id &&
        token.token_abbr === fromToken.token_abbr // must not be same chain and same token
      if (!baseRule) return false
      if (fromToken.isSF !== token.isSF) return false // if fromToken is shuttleflow token, toToken must be shuttleflow token, vice versa
      if (fromToken.isSF && fromToken.sfChain !== 'cfx')
        return token.sfChain === 'cfx' // if fromToken is shuttleflow token and is not on cfx chain, toToken must be on cfx chain
      return true
    })
  }, [fromToken, allTokenList])

  const handleChooseFromToken = token => {
    if (fromToken.sfChain !== KeyOfCfx && token.sfChain === KeyOfCfx) {
      Notification.open({
        type: 'info',
        content: 'Tokens on Conflux Core Space are only supported by Fluent Wallet.'
        // t('zgPortal.form.invalidFromAddress'),
      })
    } else if (fromToken.sfChain === KeyOfCfx && token.sfChain !== KeyOfCfx) {
      Notification.open({
        type: 'info',
        content: 'Fluent Wallet only supports tokens on Conflux Core Space.'
        // t('zgPortal.form.invalidFromAddress'),
      })
    }
    setDefaultSetLock(true)
    setFromToken(token)
    if (token.sfChain === KeyOfCfx) {
      setFromWallet(KeyOfPortal)
    } else {
      setFromWallet(lastWeb3Wallet)
    }
    setToToken({}) // reset toToken when fromToken is changed
  }

  const handleChooseToToken = token => {
    setDefaultSetLock(true)
    setToToken(token)
    if (token.sfChain === KeyOfCfx) {
      setToWallet(KeyOfPortal)
      setSameWallet(false)
    } else {
      setToWallet(KeyOfMetaMask)
    }
  }

  const web3SubEstimation = async amount => {
    if (!amount || !toToken.chain_id) {
      setToAmount('')
      return
    }
    if (fromWallet === KeyOfPortal) return
    const bridgeContract = ContractConfig[ContractType.bridge].address[fromTokenKey]
    if (!bridgeContract) return
    const contract = new Contract(
      bridgeContract,
      ZGBridge_ABI,
      web3Provider.getSigner(),
    )
    const amountBigNumber = parseUnits(amount, fromToken.decimals)
    try {
      const nativeFee = await contract.estimateFee(
        fromToken.token_address,
        amountBigNumber.toString(),
        toToken.chain_id,
        toAddress,
        new Date().getTime(),
        '0x',
      )
      setFee(nativeFee)
      const tokenFee = await contract.estimateTokenFee(
        fromToken.token_address,
        amountBigNumber.toString(),
        toToken.chain_id,
      )
      setTokenFee(tokenFee)
      if (fromToken.nativeToken === fromToken.token_address) {
        setToAmount(formatUnits(amountBigNumber.sub(nativeFee).sub(tokenFee[0]).sub(tokenFee[1]), fromToken.decimals))
      } else {
        setToAmount(formatUnits(amountBigNumber.sub(tokenFee[0]).sub(tokenFee[1]), fromToken.decimals))
      }
    } catch (err) {
      console.log('estimation failed: ', err)
    }
  }

  const cfxCoreEstimation = amount => {
    if (!amount) {
      setToAmount('')
      return
    }
    const shuttleFeeBigNumber = parseUnits(shuttleFee, fromToken.decimals)
    setFee(shuttleFeeBigNumber)
    const amountBigNumber = parseUnits(amount, fromToken.decimals)
    setToAmount(
      formatUnits(amountBigNumber.sub(shuttleFeeBigNumber), fromToken.decimals),
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setToAmountSubEstimation = useCallback(
    debounce(async amount => {
      if (fromToken.sfChain === 'cfx' || toToken.sfChain === 'cfx') {
        cfxCoreEstimation(amount)
      } else {
        await web3SubEstimation(amount)
      }
    }, 500),
    [fromToken, shuttleFee, toToken, fromToken, web3Provider],
  )

  const handleSetFromAmount = amount => {
    try {
      amount && parseUnits(amount, fromToken.decimals)
    } catch (err) {
      console.log('invalid amount')
      return
    }
    setFromAmount(amount)
    setToAmountSubEstimation(amount)
  }

  const approveEVMToken = async () => {
    if (fromToken.token_address === ChainConfig[fromTokenKey]?.nativeToken) return
    if (toToken.sfChain === KeyOfCfx && fromToken.origin === KeyOfCfx) return // for shuttle in, cfx-native token does not need approve
    const tokenContract = new Contract(
      fromToken.token_address,
      ERC20_ABI,
      web3Provider.getSigner(),
    )
    const contractAddr = fromToken.isSF ? ContractConfig[ContractType.depositRelayer].address[fromTokenKey] : ContractConfig[ContractType.bridge].address[fromTokenKey];
    const hasApproved = await tokenContract.allowance(
      fromAddress,
      contractAddr,
    )
    if (hasApproved.gte(parseUnits(fromAmount, fromToken.decimals))) return
    const gas = await tokenContract.estimateGas.approve(
      contractAddr,
      MaxUint256,
    )
    await tokenContract.approve(
      contractAddr,
      MaxUint256,
      {
        gasLimit: gas.add(gas.div(10)),
      },
    )
    let count = 0
    const maxCount = 30 // max check 30 times
    await new Promise(resolve => {
      setInterval(async () => {
        const hasApproved = await tokenContract.allowance(
          fromAddress,
          contractAddr,
        )
        count++;
        if (hasApproved.gte(parseUnits(fromAmount, fromToken.decimals))) {
          resolve()
        }
        if (count > maxCount) {
          resolve()
        }
      }, 2000)
    })
  }

  const drContractAddress = ContractConfig[ContractType.depositRelayerCfx]?.address?.[toToken.sfChain];
  function contractApprove(tokenContract, value, gas, storage) {
    return tokenContract
      .approve(drContractAddress, value)
      .sendTransaction({
        gas: gas ? calculateGasMargin(gas, 0.5) : undefined,
        from: fromAddress,
        storageLimit: storage ? calculateGasMargin(storage, 0.5) : undefined,
      })
      .confirmed()
      .catch((err) => {
        console.log('approve error', err)
      });
  }

  const approveCFXToken = async () => {
    const provider = window?.confluxJS;
    if (!provider) return;
    if (fromToken.token_address === ChainConfig[fromTokenKey]?.nativeToken) return;
    if (fromToken.origin !== KeyOfCfx) return; // for shuttle out, non-cfx-native token does not need approve
    if (toToken.sfChain === KeyOfBtc) return;
    const tokenContract = provider.Contract({
      address: fromToken.token_address,
      abi: ERC20_ABI,
    });
    const approvedNumber = await tokenContract.allowance(
      fromAddress,
      drContractAddress,
    );
    if (new Big(approvedNumber.toString()).gte(parseUnits(fromAmount, fromToken.decimals))) return;
    await tokenContract
      .approve(drContractAddress, MaxUint256)
      .estimateGasAndCollateral({
        from: fromAddress,
      })
      .then((estimateData) => {
        return contractApprove(
          tokenContract,
          MaxUint256,
          estimateData?.gasLimit,
          estimateData?.storage
        );
      })
      .catch((error) => {
        console.log('cfx token allowance error', error)
      });
  }

  const approveToken = async () =>  {
    try {
      if (fromWallet === KeyOfPortal) await approveCFXToken();
      if (fromWallet !== KeyOfPortal) await approveEVMToken();
    } catch (err) {
      console.log('approve allowance failed: ', err)
      setTransactionStatus('failed')
      throw(err);
    }
  }

  useEffect(() => {
    setToAmountSubEstimation(fromAmount)
  }, [chainId, fromAmount, fromToken.id, toToken, shuttleFee, setToAmountSubEstimation])

  const handleCloseFromWalletModal = () => {
    setShowFromWalletModal(false)
  }
  const handleOpenFromWalletModal = () => {
    setShowFromWalletModal(true)
  }
  const handleCloseToWalletModal = () => {
    setShowToWalletModal(false)
  }
  const handleOpenToWalletModal = () => {
    setShowToWalletModal(true)
  }

  const hideFromAccount = useMemo(() => {
    return isFromChainBtc;
  }, [isFromChainBtc]);
  const hideToAccount = useMemo(() => {
    return isToChainBtc;
  }, [isToChainBtc]);
  const displayToAddress = useMemo(() => {
    if (isToChainBtc) return toBtcAddress;
    return manualToAddress?.length ? manualToAddress : toAddress;
  }, [isToChainBtc, toBtcAddress, toAddress, manualToAddress]);

  const pendingList = useMemo(() => {
    return pendingHistory.filter(item => item.status === TransactionStatus.pending);
  }, [pendingHistory]);

  const minimalVal = isFromCfx
    ? minimal_out_value
      ? minimal_out_value.toString(10)
      : '0'
    : minimal_in_value
      ? minimal_in_value.toString(10)
      : '0'

  const errorObj = useMemo(() => {
    const validateData = (value) => {
      if ((!isFromChainBtc && !fromAddress) || !isChainSameAsFromToken || fromAmount === '') return ''
      let error = null
      if (isNumber(value)) {
        const balanceVal = balance
          ? formatUnits(new Big(balance).toString(), fromToken.cfxDecimals || fromToken.decimals || 18).toString()
          : null
        const valBig = new Big(value || 0)
        if (valBig.lt('0.000001') && minimalVal === '0') {
          // when min = 0 should >= 0.000001
          error = {str: 'error.mustGteCommonMin'}
        } else if (valBig.lt(minimalVal) && minimalVal !== '0') {
          // when min > 0 should >= min
          error = {
            str: 'error.mustGteMin',
            obj: {value: minimalVal},
          }
        } else if (!isFromChainBtc && balanceVal && valBig.gt(balanceVal)) {
          // should <= balance
          error = {str: 'error.mustLteMax'}
        }
      } else {
        //not a valid number
        error = {str: 'error.inputValidAmount'}
      }
      return error
    }

    return validateData(fromAmount);
  }, [fromAmount, balance, fromAddress, minimalVal, isFromChainBtc, fromToken.decimals, isChainSameAsFromToken])

  const disableTransactionButton = useMemo(() => {
    if (!fromToken || !toToken || Number(fromAmount) === 0 || !(toAddress || manualToAddress) || !toToken.symbol || !fromToken.symbol || Number(toAmount) === 0)
      return true
    if (fromToken.chain_id === toToken.chain_id) return true
    if (errorObj) return true;
    if (isToChainBtc && !checkBtcAddress(toBtcAddress)) return true
    return false
  }, [fromToken, toToken, fromAmount, toAddress, manualToAddress, errorObj, toBtcAddress, toAmount])

  // const { data } = useFeeData();
  const setAmountToMax = async () => {
    if (isFromChainBtc) return;
    // let gasFee = new Big(0);
    // if (fromWallet === KeyOfPortal && fromToken.symbol === 'CFX') {
    //   gasFee = BigNumber.from(parseUnits('0.1'), 18);
    // }
    // if (fromWallet !== KeyOfPortal && fromToken.token_address === ChainConfig[fromTokenKey]?.nativeToken){
    //   gasFee = BigNumber.from(data.gasPrice).mul(BigNumber.from(21000));
    // }
    let isNativeToken = false;
    if (fromToken.nativeToken === fromToken.token_address) {
      isNativeToken = true;
    } else if (fromWallet === KeyOfPortal && fromToken.symbol === 'CFX') {
      isNativeToken = true;
    }
    const balanceVal = balance
      ? (
        isNativeToken
          ? formatUnits((BigInt(Math.trunc(balance)) * BigInt(999) / BigInt(1000)).toString(), tokenDecimal || 18).toString()
          : formatUnits(BigInt(Math.trunc(balance)).toString(), tokenDecimal || 18).toString()
      )
      : null
    if (balanceVal) {
      setFromAmount(balanceVal)
    }
  }

  // select default token pair
  useEffect(() => {
    const selectWhenNoTx = () => {
      if (fromTokenList.length) {
        const defaultFromToken = fromTokenList.find(token => token.token_abbr === 'USDC')
        !fromToken?.token_abbr && defaultFromToken && handleChooseFromToken(defaultFromToken)
        const defaultToToken = toTokenList.find(token => token.token_abbr === 'USDC')
        !toToken?.token_abbr && defaultToToken && handleChooseToToken(defaultToToken)
      }
    }

    const selectWhenHasTx = () => {
      if (!successHistory.length || !fromTokenList.length) return;
      const tokenPairMap = {};
      successHistory.forEach(tx => {
        const { fromToken, toToken } = tx;
        if (typeof fromToken.id === 'number' || typeof toToken.id === 'number') return; // old data, ignore
        const key = `${fromToken.chain_id}+${fromToken.token_abbr}+${toToken.chain_id}+${toToken.token_abbr}`;
        tokenPairMap[key] = (tokenPairMap[key] || 0) + 1;
      });
      const mostUsedTokenPair = Object.keys(tokenPairMap).sort((a, b) => tokenPairMap[b] - tokenPairMap[a])[0];
      if (!mostUsedTokenPair) return;
      const [fromChainId, fromTokenAbbr, toChainId, toTokenAbbr] = mostUsedTokenPair.split('+');
      const defaultFromToken = fromTokenList.find(token => token.token_abbr === fromTokenAbbr && token.chain_id === fromChainId);
      const defaultToToken = fromTokenList.find(token => token.token_abbr === toTokenAbbr && token.chain_id === toChainId);
      defaultFromToken && defaultFromToken.token_abbr !== fromToken.token_abbr && handleChooseFromToken(defaultFromToken);
      defaultToToken && defaultToToken.token_abbr !== toToken.token_abbr && handleChooseToToken(defaultToToken);
    }

    if (defaultSetLock) return; // once user has selected token, don't auto select
    if (successHistory.length) {
      selectWhenHasTx();
    } else {
      selectWhenNoTx();
    }
  }, [fromTokenList, successHistory, fromToken, toToken, setFromToken, setToToken, toTokenList, defaultSetLock])

  const errorBtcAddressMsg = toBtcAddress && (checkBtcAddress(toBtcAddress) ? undefined : t('destinationInvalidErr'))

  const [bridgeContract, setBridgeContract] = useState();
  const [poolContract, setPoolContract] = useState();
  useEffect(() => {
    if (!fromTokenKey || !web3Provider) return;
    const bridgeContract = ContractConfig[ContractType.bridge].address[fromTokenKey]
    if (!bridgeContract) return
    const contract = new Contract(
      bridgeContract,
      ZGBridge_ABI,
      web3Provider.getSigner(),
    )
    setBridgeContract(contract);
  }, [fromTokenKey, web3Provider])

  useEffect(() => {
    if (!fromTokenKey || !web3Provider) return;
    const poolContract = ContractConfig[ContractType.pool].address[fromTokenKey]
    if (!poolContract) return
    const contract = new Contract(
      poolContract,
      Pool_ABI,
      web3Provider.getSigner(),
    )
    setPoolContract(contract);
  }, [fromTokenKey, web3Provider])

  return (
    // eslint-disable-next-line
    <div
      className={`flex flex-col w-full mx-0 w-[327px] md:mx-[32px] md:w-[704px] lg:w-[944px] xl:w-[1008px] bg-white rounded-[40px] pt-0 overflow-y-auto bg-opacity-80 pt-12 shadow-zg-1 ${className}`}
      onClick={onClick}
      ref={ref}
    >
      <div className="lg:mx-[64px] md:mx-[40px] mx-[24px] pb-[54px]">
        <p className='text-[24px] font-semibold mb-[28px]'>{t('zgPortal.form.formTitle')}</p>
        <p className='mb-[8px] text-[#7B7B7B] text-[16px] font-medium'>{t('zgPortal.form.fromTitle')}</p>
        <GroupBox
          tokenInfo={fromToken}
          setToken={handleChooseFromToken}
          address={fromAddress}
          setAmount={handleSetFromAmount}
          amount={fromAmount}
          tokenList={fromTokenList}
          handleOpenWalletModal={handleOpenFromWalletModal}
          wallet={fromWallet}
          hideAccount={hideFromAccount}
          setMax={setAmountToMax}
          tokenModalTitle={t('zgPortal.tokenModal.sourceTitle')}
          balance={displayBalance}
          bridgeContract={bridgeContract}
          poolContract={poolContract}
        />
        {errorObj && (
          <p className="text-xs text-error mt-2">{t(errorObj.str, errorObj.obj)}</p>
        )}
        <div className="flex items-center justify-center mt-[16px] mb-[16px]">
          <SVGZGFlip onClick={handleClickFlip} className='cursor-pointer w-[24px] h-[24px]' />
        </div>
        <p className='mb-[8px] text-[#7B7B7B] text-[16px] font-medium'>{t('zgPortal.form.toTitle')}</p>
        <GroupBox
          tokenInfo={toToken}
          setToken={handleChooseToToken}
          address={displayToAddress}
          amount={toAmount}
          tokenList={toTokenList}
          disableInput
          handleOpenWalletModal={handleOpenToWalletModal}
          wallet={toWallet}
          hideAccount={hideToAccount}
          tokenModalTitle={t('zgPortal.tokenModal.destinationTitle')}
        />
        {isToChainBtc && (
          <ToBtcAddress
            btcAddressVal={toBtcAddress}
            onAddressInputChange={(e) => setToBtcAddress(e.target.value)}
            errorBtcAddressMsg={errorBtcAddressMsg}
          />
        )}
        <div className="flex justify-center mt-14">
          {isChainSameAsFromToken ? (
            <Button
              className="w-full md:w-auto px-[60px] md:px-[86px]"
              onClick={handleStartTransaction}
              disabled={disableTransactionButton}
            >
              {t('zgPortal.form.transactionButton')}
            </Button>
          ) : (
            <Button className="w-full md:w-auto px-[60px] md:px-[86px]" onClick={handleSwitchNetwork}>
              {t('zgPortal.form.switchNetwork')}
            </Button>
          )}
        </div>
        {!!pendingList?.length && <PendingTxList list={pendingList} />}
        <TransactionModal
          open={showTransactionModal}
          onClose={() => setShowTransactionModal(false)}
          status={transactionStatus}
          onClickResend={handleStartTransaction}
          fromAddress={fromAddress}
          toAddress={manualToAddress || toAddress}
        />
        <BTCTransactionModal
          open={showBTCModal}
          onClose={() => setShowBTCModal(false)}
          toAddress={manualToAddress || toAddress}
        />
        <FromWalletModal
          open={showFromWalletModal}
          onClose={handleCloseFromWalletModal}
        />
        <ToWalletModal
          open={showToWalletModal}
          onClose={handleCloseToWalletModal}
        />
      </div>
    </div>
  )
})

Form.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Form
