import { useCallback, useEffect, useState } from "react";

import { useWallet } from "../../../hooks/useZGWallet";
import { useZGFormState } from "../../../state/zgForm";
import { zgTokenListImpl } from "../../../hooks/useAllTokenList";
import yourPools from "../manager/yourPools";

const useYourPools = () => {
  const { fromWallet } = useZGFormState();
  const { address } = useWallet(fromWallet);

  const [tableData, setTableData] = useState([]);

  const updateTableData = useCallback(async (force = false) => {
    await zgTokenListImpl.fetch();
    yourPools.fetch(address, zgTokenListImpl.getTokenList(), force).then(() => {
      setTableData(yourPools.getPoolTableData());
    });
  }, [address])

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return {
    tableData,
    updateTableData,
  };
}

export default useYourPools;
