import PropTypes from 'prop-types'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {ZGCheck, ZGCopy, ZGOpen} from '../../../assets/svg'
import {useCallback, useState} from 'react'
import {Tooltip} from '../../../components'

function ScanItem({display, text, prefix}) {
  const [copied, setCopied] = useState(false)

  const copy = useCallback(() => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [])

  const url = prefix + text
  const displayUrl = (url || '').substring(0, 26) + '...'

  const openNewUrl = () => {
    window.open(url, '_blank')
  }

  return (
    <div className="flex">
      <span className="mr-4">{display}</span>
      {text && <>
        <CopyToClipboard text={text} onCopy={() => copy()}>
          {copied ? (
            <Tooltip placement="bottom" content="Copied!">
              <ZGCheck className="text-gray-40 w-4 h-4 flex-shrink-0 cursor-pointer" />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" content="Copy to Clipboard">
              <ZGCopy className="text-gray-40 w-4 h-4 flex-shrink-0 cursor-pointer" />
            </Tooltip>
          )}
        </CopyToClipboard>
        <Tooltip className="ml-1" placement="bottom" content={displayUrl}>
          <ZGOpen
            className="text-gray-40 w-4 h-4 flex-shrink-0 cursor-pointer"
            onClick={openNewUrl}
          />
        </Tooltip>
      </>}
    </div>
  )
}

ScanItem.propTypes = {
  display: PropTypes.string,
  text: PropTypes.string,
  prefix: PropTypes.string,
}

export default ScanItem
