import {useMemo} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import { formatUnits } from '@ethersproject/units'

import {Modal, Button} from '../../../components'
import {useZGFormState} from '../../../state/zgForm'
import {StatusError, StatusSuccess, StatusWaiting} from '../../../assets/img'
import { useIsToChainBtc } from '../../../state/zgForm'
import Account from './Account'
import { ChainConfig } from '../../../constants'

function TransactionModal({open, onClose, status, onClickResend, fromAddress, toAddress}) {
  const {t} = useTranslation()

  const {fromToken, toToken, fromAmount, toAmount, fee, toBtcAddress, fromWallet, toWallet, tokenFee} =
    useZGFormState()
  const isToChainBtc = useIsToChainBtc()

  const handleCancel = () => {
    onClose && onClose()
  }

  const topIcon = useMemo(() => {
    switch (status) {
      case 'waiting':
        return StatusWaiting
      case 'success':
        return StatusSuccess
      case 'failed':
        return StatusError
      default:
        return StatusWaiting
    }
  }, [status])

  const title = useMemo(() => {
    switch (status) {
      case 'waiting':
        return t('zgPortal.transactionModal.confirmTitle')
      case 'success':
        return t('zgPortal.transactionModal.successTitle')
      case 'failed':
        return t('zgPortal.transactionModal.failedTitle')
      default:
        return t('zgPortal.transactionModal.confirmTitle')
    }
  }, [status, t])

  const notice = useMemo(() => {
    switch (status) {
      case 'waiting':
        return t('zgPortal.transactionModal.refreshNotice')
      case 'success':
        return ''
      case 'failed':
        return ''
      default:
        return t('zgPortal.transactionModal.refreshNotice')
    }
  }, [status, t])

  const fromTokenChainInfo = useMemo(() => {
    return Object.values(ChainConfig).find(chain => chain.id === fromToken.chain_id) || {};
  }, [fromToken.chain_id])

  const displayFee = useMemo(() => {
    if (!fee) return '0';
    if (!fromToken.isSF) { // for zg, the fee is native token + source token
      if (fromTokenChainInfo.tokenName === fromToken.symbol) {
        return formatUnits(fee.add(tokenFee[0]).add(tokenFee[1]), fromTokenChainInfo.decimals).toString() + fromTokenChainInfo.tokenName;
      }
      // const nativeFee = formatUnits(fee, fromTokenChainInfo.decimals).toString() + fromTokenChainInfo.tokenName;
      const sourceTokenFee = formatUnits(tokenFee[0].add(tokenFee[1]), fromToken.decimals).toString() + fromToken.symbol;
      return `${sourceTokenFee}`;
    }
    return formatUnits(fee, fromToken.decimals).toString() + fromToken.symbol; // for sf, the fee is from token
  }, [fee, fromToken.decimals, fromToken.isSF, fromTokenChainInfo, tokenFee, fromToken.symbol])

  const displayToAddress = useMemo(() => {
    if (isToChainBtc) return toBtcAddress;
    return toAddress;
  }, [isToChainBtc, toBtcAddress, toAddress]);

  return (
    <Modal
      width="w-[992px]"
      open={open}
      closable={false}
      content={
        <div className="flex flex-col items-center">
          <img className="w-16" src={topIcon} alt="tunnel" />
          <p className="text-lg font-medium mt-14 mb-6">
            {title}
          </p>
          <p className="text-base mb-12">
            {notice}
          </p>
          <div className="mb-24 w-[586px] border rounded-xl border-[#EAECEF]">
            <div className="flex justify-between items-center border-b border-[#EAECEF] p-5">
              <div>
                <p className='text-[16px] font-medium text-[#7B7B7B]'>{t('zgPortal.transactionModal.from')}</p>
                <p className='text-[24px] color-[#1E1E1E] font-semibold leading-[130%]'>
                  {fromAmount} {fromToken.symbol}
                </p>
                <p className='text-[14px] font-medium text-[#7B7B7B]'>{t('zgPortal.transactionModal.fee')} {displayFee}</p>
              </div>
              <Account tokenInfo={fromToken} account={fromAddress} hideEdit wallet={fromWallet} />
            </div>
            <div className="flex justify-between items-center p-5">
              <div>
                <p className='text-[16px] font-medium text-[#7B7B7B]'>{t('zgPortal.transactionModal.to')}</p>
                <p className='text-[24px] color-[#1E1E1E] font-semibold leading-[130%]'>
                  {toAmount} {toToken.symbol}
                </p>
              </div>
              <Account tokenInfo={toToken} account={displayToAddress} hideEdit wallet={toWallet} />
            </div>
          </div>
          <div className="flex gap-8">
            {status === 'success' ? (
              <Button
                onClick={handleCancel}
                className="w-[320px]"
              >
                {t('zgPortal.transactionModal.newTransactionButton')}
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  className="w-[320px]"
                  disabled={status === 'waiting'}
                >
                  {t('zgPortal.transactionModal.cancelButton')}
                </Button>
                <Button onClick={onClickResend} className="w-[320px]" disabled={status === 'waiting'}>
                  {t('zgPortal.transactionModal.resendButton')}
                </Button>
              </>
            )}
          </div>
        </div>
      }
    />
  )
}

TransactionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  status: PropTypes.string,
  onClickResend: PropTypes.func,
  fromAddress: PropTypes.string,
  toAddress: PropTypes.string,
}

export default TransactionModal
