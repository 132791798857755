import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Loading as LoadingSvg} from '../../../assets/svg'
import SVGZGConnectWallet from '../../../assets/svg/ZGConnectWallet';
import SVGZGEditWallet from '../../../assets/svg/ZGEditWallet';
import { useZGFormState } from '../../../state/zgForm';
import { WalletIcon } from '../../components';
import SvgPlus from '../../../assets/svg/color/Plus';
import { Button, Input, Modal, Tabs } from '../../../components';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { Contract } from '@ethersproject/contracts';
import { ERC20_ABI } from '../../../abi';
import { useWallet } from '../../../hooks/useZGWallet';
import { MaxUint256 } from '@ethersproject/constants';
import { KeyOfPortal } from '../../../constants';
import { getAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';


const Account = ({ account, handleOpenWalletModal, wallet, hideEdit, balance, bridgeContract, tokenInfo, poolContract, hideStake }) => {
  const { walletInit } = useZGFormState()
  const { t } = useTranslation();
  const showBalance = useMemo(() => {
    return balance !== undefined;
  }, [balance]);
  const [unstakeStatus, setUnstakeStatus] = useState('default');
  const [canWithdraw, setCanWithdraw] = useState(false);
  const { web3Provider } = useWallet(wallet);

  const checksumAccount = useMemo(() => {
    if (!account) return '';
    if (account.startsWith('0x')) {
      return getAddress(account);
    }
    return account;
  }, [account]);

  const getValidateStatus = useCallback(async () => {
    if (!account || !poolContract || !account.startsWith('0x')) {
      return;
    }
    try {
      const requested = await poolContract.userUnstakeRequestMap(
        checksumAccount,
        tokenInfo.token_address,
      )
      const canWithdraw = await poolContract.canWithdraw(
        checksumAccount,
        tokenInfo.token_address,
      );
      setCanWithdraw(canWithdraw);
      if (canWithdraw) {
        setUnstakeStatus('unstaked');
      } else if (requested) {
        setUnstakeStatus('requested');
      } else {
        setUnstakeStatus('default');
      }
    } catch (e) {
      if (String(e).includes('invalidated user')) {
        setUnstakeStatus('requested');
      } else {
        setUnstakeStatus('default');
      }
    }
  }, [poolContract, account, tokenInfo, checksumAccount]);

  useEffect(() => {
    getValidateStatus();
    const timer = setInterval(() => {
      getValidateStatus();
    }, 3000);

    return () => {
      clearInterval(timer);
    }
  }, [getValidateStatus, account]);

  const displayAccount = useMemo(() => {
      if (!account) return null;
      return `${account.slice(0, 6)}...${account.slice(-4)}`;
  }, [account]);

  const handleConnectWallet = () => {
    handleOpenWalletModal();
  }

  const [showFastPathModal, setShowFastPathModal] = useState(false);
  const [stakeInput, setStakeInput] = useState('');

  const disableStake = () => {
    if (!bridgeContract) {
      return true;
    }
    if (stakeInput === '' || !Number(stakeInput)) {
      return true;
    }
    if (processing) return true;
    if (getInputError() !== '') return true;
    return false;
  }

  const approveAllowance = async (contract, tokenInfo) => {
    if (tokenInfo.token_address === tokenInfo.nativeToken) return
    const tokenContract = new Contract(
      tokenInfo.token_address,
      ERC20_ABI,
      web3Provider.getSigner(),
    )
    const hasApproved = await tokenContract.allowance(
      account,
      contract.address,
    )
    if (hasApproved.gte(parseUnits(stakeInput, tokenInfo.decimals))) return
    const gas = await tokenContract.estimateGas.approve(
      contract.address,
      MaxUint256,
    )
    await tokenContract.approve(
      contract.address,
      MaxUint256,
      {
        gasLimit: gas.add(gas.div(10)),
      },
    )
    let count = 0
    const maxCount = 30 // max check 30 times
    await new Promise(resolve => {
      setInterval(async () => {
        const hasApproved = await tokenContract.allowance(
          account,
          contract.address,
        )
        count++;
        if (hasApproved.gte(parseUnits(stakeInput, tokenInfo.decimals))) {
          resolve()
        }
        if (count > maxCount) {
          resolve()
        }
      }, 2000)
    })
  }

  const [processing, setProcessing] = useState(false);
  const handleStake = async () => {
    if (disableStake()) {
      return;
    }
    setProcessing(true);
    try {
      await approveAllowance(poolContract, tokenInfo);
      const valueInBigInt = parseUnits(stakeInput, tokenInfo.decimals);
      const gas = await poolContract.estimateGas.stake(
        tokenInfo.token_address,
        valueInBigInt,
        {
          value: tokenInfo.token_address === tokenInfo.nativeToken ? valueInBigInt : BigInt(0),
        }
      );
      const tx = await poolContract.stake(
        tokenInfo.token_address,
        valueInBigInt,
        {
          value: tokenInfo.token_address === tokenInfo.nativeToken ? valueInBigInt : BigInt(0),
          gasLimit: gas.mul(2),
        },
      );
      await tx.wait();
    } catch (err) {
      console.log(err);
    } finally {
      setProcessing(false);
    }
    fetchStakedAmount();
  }

  const [stakedAmount, setStakedAmount] = useState(BigInt(0));
  const fetchStakedAmount = useCallback(
    async () => {
      if (!poolContract || !account) {
        return;
      }
      try {
        const amount = await poolContract.userStakedAmount(
          account,
          tokenInfo.token_address,
        );
        setStakedAmount(amount);
      } catch (e) {
        console.log(e);
        setStakedAmount(BigInt(0));
      }
    }
  , [account, poolContract, tokenInfo]);

  useEffect(() => {
    if (account) {
      fetchStakedAmount();
    }
  }, [account, fetchStakedAmount])

  const displayStakedAmount = useMemo(() => {
    if (!tokenInfo) return '';
    return formatUnits(stakedAmount, tokenInfo.decimals)
  }, [stakedAmount, tokenInfo]);

  const [stakeMode, setStakeMode] = useState('Stake');

  const disableUnstake = () => {
    if (!bridgeContract) {
      return true;
    }
    if (processing) return true;
    if (canWithdraw && stakeMode === 'Withdraw') {
      return false;
    }
    if (BigNumber.from(0).eq(stakedAmount)) {
      return true;
    }
    if (unstakeStatus === 'requested') return true;
    return false;
  }

  const handleUnstake = async () => {
    if (disableUnstake()) {
      return;
    }
    try {
      setProcessing(true);
      await fetchStakedAmount();
      if (BigNumber.from(0).eq(stakedAmount)) {
        return;
      }
      const unlockGas = await poolContract.estimateGas.unstake(
        tokenInfo.token_address,
      );
      const tx = await poolContract.unstake(
        tokenInfo.token_address,
        {
          gasLimit: unlockGas.mul(2),
        },
      );
      await tx.wait();
      setUnstakeStatus('requested');
      setProcessing(false);
      fetchStakedAmount();
    } catch (e) {
      console.log(e)
    } finally {
      setProcessing(false);
    }
  }

  const handleWithdraw = async () => {
    setProcessing(true);
    const gas = await poolContract.estimateGas.withdraw(
      tokenInfo.token_address,
    );
    const tx = await poolContract.withdraw(
      tokenInfo.token_address,
      {
        gasLimit: gas.mul(2),
      },
    );
    await tx.wait();
    fetchStakedAmount();
    setProcessing(false);
  }

  const getFastPathLabel = () => {
    if (unstakeStatus === 'unstaked') {
      return 'Withdrawable'
    }
    if (unstakeStatus === 'requested') {
      return 'Unstaking'
    }
    return 'Fast Path'
  }

  const getInputError = () => {
    if (balance && Number(stakeInput) > Number(balance)) {
      return 'Insufficient balance'
    }
    return ''
  }

  const handleStakeInputChange = (e) => {
    // must be no less than 0
    if (Number(e.target.value) < 0) {
      return;
    }
    setStakeInput(e.target.value)
  }

  return (
      <>
          {
            (account) ? (
                <div className='flex flex-0 items-center justify-between md:w-[215px] w-full'>
                  {wallet ? <WalletIcon type={wallet} className='mr-[10px]' /> : <div className='w-[30px] mr-[10px]' />}
                  <div className='mr-[24px]'>
                    <p className='text-black text-[16px] font-medium'>{displayAccount}</p>
                    {showBalance && (
                      <>
                        <p className='text-[#424242] text-[14px] font-medium'>
                          {t('balance')} {balance}
                        </p>
                        {wallet !== KeyOfPortal && !hideStake && tokenInfo.token_address && !tokenInfo.isSF && (
                          <p className='text-[#424242] text-[14px] font-medium flex gap-[8px] items-center'>
                            {getFastPathLabel()}: {displayStakedAmount} <SvgPlus onClick={() => { setShowFastPathModal(true) }} className='w-[16px] h-[16px] cursor-pointer' />
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  {!hideEdit && <SVGZGEditWallet onClick={handleOpenWalletModal} className='cursor-pointer' />}
                </div>
            ) : (
                <button className='flex gap-2 justify-center items-center md:py-[8px] py-[4px] px-[48px] rounded-[24px] md:w-auto w-full bg-primary' onClick={handleConnectWallet}>
                  {walletInit && <>
                    <SVGZGConnectWallet style={{
                      color: 'white'
                    }} />
                    <p className='text-sm font-medium text-white md:text-[16px] text-[14px]'>{t('zgPortal.form.connect')}
                    </p>
                  </>}
                  {!walletInit && <LoadingSvg className={`animate-spin text-gray-20 w-6 h-6`} />}
                </button>
            )
          }
          <Modal
            width="w-[500px]"
            open={showFastPathModal}
            closable={!processing}
            onClose={() => {
              if (processing) return;
              setShowFastPathModal(false)
              setStakeMode('Stake')
            }}
            content={(
              <div className='w-full flex flex-col gap-[20px] items-center justify-center'>
                <Tabs
                  tabs={[
                    { label: 'Stake' },
                    { label: 'Withdraw' },
                  ]}
                  onSelect={tab => setStakeMode(tab.label)}
                />
                {
                  stakeMode === 'Stake' ? (
                    unstakeStatus !== 'default' ? <p>Unstaking, can not stake</p> : <Input value={stakeInput} onChange={handleStakeInputChange} type="number" min="0" errorMessage={getInputError()} />
                  ) : (
                    <div className='flex flex-col gap-[12px] items-center'>
                      <p className='h-[54px] flex items-center'>Staked amount: {displayStakedAmount}</p>
                      {unstakeStatus === 'requested' && (
                        <p>Unstaking, wait for 1000 blocks to confirm...</p>
                      )}
                    </div>
                  )
                }
                {
                  stakeMode === 'Stake' ? (
                    <Button className='w-[200px]' disabled={disableStake()} onClick={handleStake}>
                      {processing ? <p className='flex items-center gap-[8px]'>
                        <LoadingSvg className='animate-spin w-6 h-6' /> Processing...
                      </p> : 'Confirm'}
                    </Button>
                  ) : (
                    <Button className='w-[200px]' disabled={disableUnstake()} onClick={unstakeStatus === 'unstaked' ? handleWithdraw : handleUnstake}>
                      {processing ? <p className='flex items-center gap-[8px]'>
                        <LoadingSvg className='animate-spin w-6 h-6' /> Processing...
                      </p> : unstakeStatus === 'unstaked' ? 'Withdraw' : 'Unstake'}
                    </Button>
                  )
                }
              </div>
            )}
          />
      </>
  )
}

Account.propTypes = {
  tokenInfo: PropTypes.object,
  account: PropTypes.string,
  handleOpenWalletModal: PropTypes.func,
  wallet: PropTypes.string,
  hideEdit: PropTypes.bool,
  balance: PropTypes.string,
  bridgeContract: PropTypes.object,
  poolContract: PropTypes.object,
  hideStake: PropTypes.bool,
}

export default Account;
