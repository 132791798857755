import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useZGFormState } from "../../../state/zgForm";
import { useWallet } from "../../../hooks/useZGWallet";
import { Button } from "../../../components";
import switchWeb3Network from "../../../utils/switchWeb3Network";
import { findChainKeyById } from "../../../constants";

const TransactionButton = ({ token, handleTransaction, disabled }) => {
  const { t } = useTranslation();
  const { fromWallet } = useZGFormState();
  const { chainId } = useWallet(fromWallet);

  const handleSwitchNetwork = async () => {
    await switchWeb3Network(fromWallet, token, findChainKeyById(token.chain_id));
  }

  return chainId === token.chain_id ? (
      <Button className="w-full md:w-auto px-[60px] md:px-[86px]" onClick={handleTransaction} disabled={disabled}>{t('zgPortal.form.transactionButton')}</Button>
    ) : (
      <Button className="w-full md:w-auto px-[60px] md:px-[86px]" onClick={handleSwitchNetwork}>{t('zgPortal.form.switchNetwork')}</Button>
    )
}

TransactionButton.propTypes = {
  token: PropTypes.object.isRequired,
  handleTransaction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TransactionButton;
