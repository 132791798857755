import { Contract } from "@ethersproject/contracts";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { useTranslation } from "react-i18next";

import { tokenContractsInfo, ContractConfig, ContractType } from "../../constants";
import { useZGFormState } from "../../state/zgForm";
import { useWallet } from "../../hooks/useZGWallet";
import { ERC20_ABI } from "../../abi";
import { Input } from "../../components";
import Account from "../ZGPortal/Form/Account";

import TransactionButton from "./components/TransactionButton";
import PoolInfo from "./components/PoolInfo";
import TransactionModal from "./components/TransactionModal";
import { toChecksum } from "@fluent-wallet/account";
import useYourPools from "./hooks/useYourPools";

const RemoveLiquidty = ({ token, openWalletModal }) => {
  const { fromWallet } = useZGFormState();
  const { web3Provider, address, chainId } = useWallet(fromWallet);
  const [amount, setAmount] = useState('');
  const [peggedTokenBalance, setPeggedTokenBalance] = useState('');
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('waiting')
  const [errorInfo, setErrorInfo] = useState('');
  const { updateTableData } = useYourPools();

  const { t } = useTranslation();

  const updatePeggedTokenBalance = useCallback(async () => {
    if (!web3Provider) return;
    const [chainId, tokenAddress] = token.id.split('+');
    const info = tokenContractsInfo[`${chainId}+${(toChecksum(tokenAddress))}`] || tokenContractsInfo[`${chainId}+${tokenAddress}`]
    const peggedToken = info?.pegged;
    if (!peggedToken) return;
    const contract = new Contract(peggedToken, ERC20_ABI, web3Provider.getSigner());
    const balance = await contract.balanceOf(address);
    setPeggedTokenBalance(balance);
  }, [web3Provider, address, token])

  useEffect(() => {
    const timer = setInterval(updatePeggedTokenBalance, 1000);
    return () => clearInterval(timer);
  }, [web3Provider, address, token, chainId, updatePeggedTokenBalance])

  const setMax = async () => {
    setAmount(formatUnits(peggedTokenBalance || 0, token.decimals));
  }

  const handleClickRemoveLiquidity = async () => {
    try {
      setTransactionStatus('waiting');
      setShowTransactionModal(true);
      const contractConfig = ContractConfig[ContractType.bridge];
      const contract = new Contract(contractConfig.address[token.key], contractConfig.abi, web3Provider.getSigner());
      const gas = await contract.estimateGas.removeLiquidity(token.token_address, parseUnits(amount, token.decimals).toString());
      const tx = await contract.removeLiquidity(
        token.token_address,
        parseUnits(amount, token.decimals).toString(),
        {
          gasLimit: gas.add(gas.div(10)),
        },
      )
      await tx.wait();
      updateTableData(true);
      setTransactionStatus('success');
    } catch (err) {
      setTransactionStatus('failed');
      console.log(err)
      if (String(err).includes('insufficient liquidity')) {
        setErrorInfo('Insufficient Liquidity')
      }
    }
  }

  const errorMessage = () => {
    if (!amount) return '';
    if (Number(amount) > Number(formatUnits(peggedTokenBalance || 0, token.decimals))) return t('error.mustLteMax');
    return '';
  }

  const handleInput = (amount) => {
    try {
      amount && parseUnits(amount, token.decimals)
    } catch (err) {
      console.log('invalid amount')
      return
    }
    setAmount(amount)
  }

  return (
    <div className="mt-[24px]">
      <p className="text-[14px] font-medium text-primary-fuscia">Total amount</p>
      <div className="w-full bg-white p-[24px] rounded-[12px] mt-[8px] flex justify-between items-center">
        <Input
          bordered={false}
          placeholder="0"
          width="w-full"
          className="md:text-[40px] text-[24px] w-full"
          containerClassName='py-0 md:py-0 px-0 md:px-0 w-full'
          value={amount}
          onChange={(e) => handleInput(e.target.value)}
        />
        <div className="flex gap-[30px]">
          <button onClick={setMax} className='border border-[#A9ABB2] rounded-[12px] px-[8px] md:py-[11px] py-[8px] bg-[#F5F5F5] font-medium md:text-[14px] text-[12px]'>{t('zgPortal.form.maxButton')}</button>
          <Account
            account={address}
            wallet={fromWallet}
            balance={Number(formatUnits(peggedTokenBalance || 0, token.decimals)).toFixed(6)}
            handleOpenWalletModal={openWalletModal}
            hideStake
          />
        </div>
      </div>
      {errorMessage() && <p className="text-[14px] text-red-500 font-medium mt-[8px]">{errorMessage()}</p>}
      <PoolInfo token={token} className='mt-[64px]' />
      <div className="w-full flex justify-center mt-[32px]">
        <TransactionButton
          token={token}
          handleTransaction={handleClickRemoveLiquidity}
          disabled={!amount || !!errorMessage()}
        />
      </div>
      <TransactionModal
        open={showTransactionModal}
        onClose={() => setShowTransactionModal(false)}
        status={transactionStatus}
        onClickResend={handleClickRemoveLiquidity}
        errInfo={errorInfo}
      />
    </div>
  );
};

RemoveLiquidty.propTypes = {
  token: PropTypes.object,
  openWalletModal: PropTypes.func.isRequired,
};

export default RemoveLiquidty;
