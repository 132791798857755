import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { zgTokenListImpl } from '../../hooks/useAllTokenList';
import { TokenIcon } from '../components';
import { ArrowLeft } from '../../assets/svg';
import { Tabs } from '../../components';

import AddLiquidity from './AddLiquidity';
import RemoveLiquidty from './RemoveLiquidity';
import { KeyOfPortal } from '../../constants';

const Operation = ({ openWalletModal, setHideWalletList }) => {
  const { id } = useParams();
  const history = useHistory();
  const [token, setToken] = useState({});
  const [tab, setTab] = useState('add');

  useEffect(() => {
    setHideWalletList([KeyOfPortal])
  }, [setHideWalletList]);

  const handleClickBack = () => {
    history.push('/pool');
  }

  useEffect(() => {
    zgTokenListImpl.fetch().then(() => {
      const tokenList = zgTokenListImpl.getTokenList();
      const matchedToken = tokenList.find(t => t.id === id.toLowerCase());
      setToken(matchedToken);
    });
  }, [id]);

  const handleSelectTab = (tab) => {
    setTab(tab.value);
  }

  return (
    <div className="pt-[24px] pb-[60px]">
      <div className='flex pl-[24px] items-center gap-[16px]'>
        <ArrowLeft className='text-[#D1D5DB]' onClick={handleClickBack} />
        <TokenIcon token={token} />
        <p className='text-[20px] font-semibold'>{`${token.chainName} ${token.symbol} Pool`}</p>
      </div>
      <div className='w-full mt-[24px]'>
        <Tabs tabs={[
          {
            label: 'Add',
            value: 'add',
          },
          {
            label: 'Remove',
            value: 'remove',
          }
        ]} onSelect={handleSelectTab} className='self-start px-[64px]' />
        <div className='h-[1px] w-full bg-G-200 mt-[16px]' />
        <div className='w-full px-[64px]'>
          {tab === 'add' && <AddLiquidity token={token} openWalletModal={openWalletModal} />}
          {tab === 'remove' && <RemoveLiquidty token={token} openWalletModal={openWalletModal} />}
        </div>
      </div>
    </div>
  );
};

Operation.propTypes = {
  openWalletModal: PropTypes.func.isRequired,
  setHideWalletList: PropTypes.func.isRequired,
};

export default Operation;
