import {useMemo} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {Modal, Button} from '../../../components'
import {StatusError, StatusSuccess, StatusWaiting} from '../../../assets/img'

function TransactionModal({open, onClose, status, onClickResend, errInfo}) {
  const {t} = useTranslation()

  const handleCancel = () => {
    onClose && onClose()
  }

  const topIcon = useMemo(() => {
    switch (status) {
      case 'waiting':
        return StatusWaiting
      case 'success':
        return StatusSuccess
      case 'failed':
        return StatusError
      default:
        return StatusWaiting
    }
  }, [status])

  const title = useMemo(() => {
    switch (status) {
      case 'waiting':
        return t('zgPortal.transactionModal.confirmTitle')
      case 'success':
        return t('zgPortal.transactionModal.successTitle')
      case 'failed':
        return errInfo || t('zgPortal.transactionModal.failedTitle')
      default:
        return t('zgPortal.transactionModal.confirmTitle')
    }
  }, [status, t, errInfo])

  const notice = useMemo(() => {
    switch (status) {
      case 'waiting':
        return t('zgPortal.transactionModal.refreshNotice')
      case 'success':
        return ''
      case 'failed':
        return ''
      default:
        return t('zgPortal.transactionModal.refreshNotice')
    }
  }, [status, t])


  return (
    <Modal
      width="w-[992px]"
      open={open}
      closable={false}
      content={
        <div className="flex flex-col items-center">
          <img className="w-16" src={topIcon} alt="tunnel" />
          <p className="text-lg font-medium mt-14 mb-6">
            {title}
          </p>
          <p className="text-base mb-12">
            {notice}
          </p>
          <div className="flex gap-8">
            {status === 'success' ? (
              <Button
                onClick={handleCancel}
                className="w-[320px]"
              >
                {t('zgPortal.transactionModal.newTransactionButton')}
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  className="w-[320px]"
                  disabled={status === 'waiting'}
                >
                  {t('zgPortal.transactionModal.cancelButton')}
                </Button>
                <Button onClick={onClickResend} className="w-[320px]" disabled={status === 'waiting'}>
                  {t('zgPortal.transactionModal.resendButton')}
                </Button>
              </>
            )}
          </div>
        </div>
      }
    />
  )
}

TransactionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  status: PropTypes.string,
  onClickResend: PropTypes.func,
  fromAddress: PropTypes.string,
  toAddress: PropTypes.string,
  errInfo: PropTypes.string,
}

export default TransactionModal
