import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MaxUint256 } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";

import { Input } from "../../components";
import { getWeb3Balance } from "../../hooks/useZGBalance";
import { useWallet } from "../../hooks/useZGWallet";
import { useZGFormState } from "../../state/zgForm";
import Account from "../ZGPortal/Form/Account";
import { AddressOne, ContractConfig, ContractType } from "../../constants";
import { ERC20_ABI } from "../../abi";

import TransactionButton from "./components/TransactionButton";
import PoolInfo from "./components/PoolInfo";
import TransactionModal from "./components/TransactionModal";
import useYourPools from "./hooks/useYourPools";

const AddLiquidity = ({ token, openWalletModal }) => {
  const [amount, setAmount] = useState('');
  const [balance, setBalance] = useState('');
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('waiting')
  const { t } = useTranslation();
  const { fromWallet } = useZGFormState();
  const { web3Provider, address, chainId } = useWallet(fromWallet);
  const { updateTableData } = useYourPools();

  useEffect(() => {
    const fetchBalance = async () => {
      if (!web3Provider) return;
      if (chainId !== token.chain_id) return;
      const balance = await getWeb3Balance(web3Provider, address, token.token_address, token.chain_id);
      setBalance(balance);
    }
    setInterval(fetchBalance, 1000);
  }, [token, address, web3Provider, chainId]);

  const setMax = async () => {
    let gas = BigNumber.from(0);
    if (token.symbol === 'CFX') {
      gas = BigNumber.from(parseUnits('0.1'), 18);
    } else if (token.token_address === AddressOne) {
      gas = BigNumber.from(parseUnits('0.0001'), 18);
    }
    if (!balance) return;
    setAmount(formatUnits(BigNumber.from(balance).sub(gas).toString(), token.decimals || 18));
  }

  const handleClickAddLiquidity = async () => {
    try {
      setTransactionStatus('waiting');
      setShowTransactionModal(true);
      const contractConfig = ContractConfig[ContractType.bridge];
      const contractAddr = contractConfig.address[token.key];

      if (token.token_address !== AddressOne) {
        const tokenContract = new Contract(
          token.token_address,
          ERC20_ABI,
          web3Provider.getSigner(),
        )
        const allowance = await tokenContract.allowance(address, contractAddr);

        if (allowance.toString() === '0') {
          const gas = await tokenContract.estimateGas.approve(contractAddr, parseUnits(amount, token.decimals).toString());
          await tokenContract.approve(contractAddr, MaxUint256, {
            gasLimit: gas.add(gas.div(10)),
          });

          let count = 0
          const maxCount = 30 // max check 30 times
          await new Promise(resolve => {
            setInterval(async () => {
              const hasApproved = await tokenContract.allowance(
                address,
                contractAddr,
              )
              count++;
              if (hasApproved.gte(parseUnits(amount, token.decimals))) {
                resolve()
              }
              if (count > maxCount) {
                resolve()
              }
            }, 2000)
          })
        }
      }

      const contract = new Contract(contractConfig.address[token.key], contractConfig.abi, web3Provider.getSigner());

      const gas = await contract.estimateGas.addLiquidity(
        token.token_address,
        parseUnits(amount, token.decimals).toString(),
        token.token_address !== AddressOne ? {} : {
          value: parseUnits(amount, token.decimals).toString(),
        }
      );
      const tx = await contract.addLiquidity(
        token.token_address,
        parseUnits(amount, token.decimals).toString(),
        token.token_address !== AddressOne ? {
          gasLimit: gas.add(gas.div(10)),
        } : {
          value: parseUnits(amount, token.decimals).toString(),
          gasLimit: gas.add(gas.div(10)),
        },
      )

      await tx.wait();

      setTransactionStatus('success');
      updateTableData(true);
    } catch (err) {
      console.log(err)
      setTransactionStatus('failed');
    }
  }

  const errorMessage = () => {
    if (!amount) return '';
    if (Number(amount) > Number(formatUnits(balance || 0, token.decimals))) return t('error.mustLteMax');
    return '';
  }

  const handleInput = (amount) => {
    try {
      amount && parseUnits(amount, token.decimals)
    } catch (err) {
      console.log('invalid amount')
      return
    }
    setAmount(amount)
  }

  return (
    <div className="mt-[24px]">
      <p className="text-[14px] font-medium text-primary-fuscia">Total amount</p>
      <div className="w-full bg-white p-[24px] rounded-[12px] mt-[8px] flex justify-between items-center">
        <Input
          bordered={false}
          placeholder="0"
          width="w-full"
          className="md:text-[40px] text-[24px] w-full"
          containerClassName='py-0 md:py-0 px-0 md:px-0 w-full'
          value={amount}
          onChange={(e) => handleInput(e.target.value)}
        />
        <div className="flex gap-[30px]">
          <button onClick={setMax} className='border border-[#A9ABB2] rounded-[12px] px-[8px] md:py-[11px] py-[8px] bg-[#F5F5F5] font-medium md:text-[14px] text-[12px]'>{t('zgPortal.form.maxButton')}</button>
          <Account
            account={address}
            wallet={fromWallet}
            balance={Number(formatUnits(balance || 0, token.decimals)).toFixed(6)}
            handleOpenWalletModal={openWalletModal}
            hideStake
          />
        </div>
      </div>
      {errorMessage() && <p className="text-[14px] text-red-500 font-medium mt-[8px]">{errorMessage()}</p>}
      <PoolInfo token={token} className='mt-[64px]' />
      <div className="w-full flex justify-center mt-[32px]">
        <TransactionButton
          token={token}
          handleTransaction={handleClickAddLiquidity}
          disabled={!amount || !!errorMessage()}
        />
      </div>
      <TransactionModal
        open={showTransactionModal}
        onClose={() => setShowTransactionModal(false)}
        status={transactionStatus}
        onClickResend={handleClickAddLiquidity}
      />
    </div>
  );
}

AddLiquidity.propTypes = {
  token: PropTypes.object.isRequired,
  openWalletModal: PropTypes.func.isRequired,
};

export default AddLiquidity;
