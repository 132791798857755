import React from 'react';
import { useReactTable, getCoreRowModel, flexRender, getSortedRowModel } from "@tanstack/react-table";
import { useHistory } from 'react-router-dom';

import { TokenIcon } from '../components'
import useAllPools from "./hooks/useAllPools";

const PoolList = () => {
  const { tableData } = useAllPools();
  const [sorting, setSorting] = React.useState([])

  const table = useReactTable({
    data: tableData,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    columns: [
      {
        header: () => (
          <p className='w-[300px] text-left'>Name</p>
        ),
        accessorKey: 'name',
        cell: (info) => (
          <div className="flex items-center gap-[12px] w-[300px]">
            <TokenIcon token={{
              token_abbr: info.row.original.token_abbr,
              chain_id: info.row.original.chainId,
            }} size={38} />
            <p className="text-G-900 text-[14px] font-semibold">{info.row.original.token.chainName} {info.getValue()}</p>
          </div>
        )
      },
      {
        header: 'Wallet Balance',
        accessorKey: 'tokenBalance',
        cell: (info) => Number(info.getValue()) ? Number(info.getValue()).toFixed(6) : '-',
        sortDescFirst: true,
      },
      {
        header: 'Liquidity',
        accessorKey: 'displayLiquidity',
        sortDescFirst: true,
      },
      {
        header: 'Volume (24h)',
        accessorKey: 'displayOneDayVolume',
      },
      {
        header: 'Volume (7d)',
        accessorKey: 'displaySevenDayVolume',
      },
    ],
    getCoreRowModel: getCoreRowModel(),
  });

  const history = useHistory();
  const handleClickPool = (pool) => {
    history.push(`/pool/${pool.chainId}+${pool.address}`);
  }

  return (
    <div>
      <div className="w-full bg-white bg-opacity-80 rounded-[40px] py-[24px] overflow-hidden">
        <div className={`px-[40px] ${tableData && tableData.length && 'pb-[16px]'}`}>
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="flex w-full justify-between">
              {headerGroup.headers.map((header) => (
                <button
                  key={header.id}
                  className="font-G-600 text-[xs] font-medium tracking-[0.28px] w-full flex jusitfy-left"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </button>
              ))}
            </div>
          ))}
        </div>
        {tableData && !!tableData.length && <div className="h-[1px] w-full bg-G-200" />}
        {tableData && !!tableData.length && (
          <div>
            {table.getRowModel().rows.map(row => (
              <button key={row.id} className="w-full px-[40px] py-[15px] flex items-center justify-between hover:bg-G-100" onClick={() => handleClickPool(row.original)}>
                {row.getVisibleCells().map(cell => (
                  <div key={cell.id} className="flex w-full items-center justify-left">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                ))}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PoolList;
