import { useCallback, useEffect, useState } from "react";

import { useWallet } from "../../../hooks/useZGWallet";
import { useZGFormState } from "../../../state/zgForm";
import { zgTokenListImpl } from "../../../hooks/useAllTokenList";
import poolList from "../manager/poolList";

const useAllPools = () => {
  const { fromWallet } = useZGFormState();
  const { address } = useWallet(fromWallet);

  const [tableData, setTableData] = useState([]);
  const [tvl, setTVL] = useState('');

  const updateTableData = useCallback(async (force = false) => {
    await zgTokenListImpl.fetch();
    poolList.fetch(address, zgTokenListImpl.getTokenList(), force).then(() => {
      setTableData(poolList.getPoolTableData());
      setTVL(poolList.getTVL().toString());
    });
  }, [address])

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return {
    tableData,
    tvl,
    updateTableData,
  };
}

export default useAllPools;
