import {Suspense, useState} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  NavLink,
} from 'react-router-dom'
import {AliveScope} from 'react-activation'
import History from '../pages/History'
import {Footer, Header, Web3Manager} from '../pages/components'
import Request from '../pages/Request'
import {Loading} from '../components'
import {
  useUpdateTxs,
  usePendingTransactions,
  useUpdateClaimedTxs,
} from '../hooks/useTransaction'
import {BgRightCircle, BgLeftCircle} from '../assets/svg'
import PoolList from './Pool'
import PoolOperation from './Pool/Operation';
import Portal from './ZGPortal/Portal'
import PortalWithMarket from './ZGPortal/PortalWithMarket'

import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { bsc, bscTestnet, confluxESpace} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public'
import { confluxESpaceTestnet } from '../constants/wagmiChains'

const { publicClient, webSocketPublicClient } = configureChains(
  IS_DEV ? [confluxESpaceTestnet, bscTestnet] : [bsc, confluxESpace],
  [publicProvider()],
)

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
})


// eslint-disable-next-line no-unused-vars
import cfx from '../utils/cfx'
import FromWalletModal from './ZGPortal/Form/FromWalletModal'
import { useLocation } from 'react-use'
import { IS_DEV } from '../utils'
// import * as Sentry from '@sentry/browser'
// import {Integrations} from '@sentry/tracing'
// import {IS_DEV} from '../utils'

// Sentry.init({
//   dsn: 'https://4d2e829843a54d21b43df7b20a8e93cf@o339419.ingest.sentry.io/5880699',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: IS_DEV ? 'development' : 'production',
// })
function getPlatformOS() {
  const userAgent = window.navigator.userAgent
  let os = null

  const isIOS =
    (/iPad|iPhone|iPod/.test(userAgent) ||
      (/Mac|Mac OS|MacIntel/gi.test(userAgent) &&
        (navigator.maxTouchPoints > 1 || 'ontouchend' in document))) &&
    !window.MSStream

  if (/Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent)) {
    os = 'Mac OS'
  } else if (isIOS) {
    os = 'iOS'
  } else if (/'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent)) {
    os = 'Windows'
  } else if (/Android/gi.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/gi.test(userAgent)) {
    os = 'Linux'
  }

  return os
}

function zoomWindows() {
  if (getPlatformOS() === 'Windows') {
    let originPixelRatio = localStorage.devicePixelRatio
    if (!originPixelRatio) {
      originPixelRatio = window.devicePixelRatio
      // 整数保存
      if (Number.isInteger(originPixelRatio)) {
        localStorage.devicePixelRatio = originPixelRatio
      }
    }

    let mqListener = function () {
      let currentPixelRatio = window.devicePixelRatio
      const zoom =
        Math.round(1000 * (currentPixelRatio / originPixelRatio)) / 10 / 100
      document.body.style.zoom = 1 / zoom

      // 移除之前的查询检测
      this.removeEventListener('change', mqListener)
      // 使用新的查询检测
      matchMedia(`(resolution: ${currentPixelRatio}dppx)`).addEventListener(
        'change',
        mqListener,
      )
    }

    matchMedia(`(resolution: ${originPixelRatio}dppx)`).addEventListener(
      'change',
      mqListener,
    )

    const zoom =
      Math.round(1000 * (window.devicePixelRatio / originPixelRatio)) / 10 / 100
    console.log({zoom})
    document.body.style.zoom = 1 / zoom
  }
}

function TxsUpdater() {
  useUpdateTxs()
  useUpdateClaimedTxs()
  usePendingTransactions()
  zoomWindows()

  return null
}

function App() {
  // useMetaMaskHostedByFluent()
  const [showFromWalletModal, setShowFromWalletModal] = useState(false);
  const [hideWalletList, setHideWalletList] = useState([]);
  const [hideMarketManual, setHideMarketManual] = useState(false);
  const hideMarket = localStorage.getItem('isMarketHide')
  const {pathname} = useLocation()

  const handleCloseFromWalletModal = () => {
    setShowFromWalletModal(false)
  }
  const handleOpenFromWalletModal = () => {
    setShowFromWalletModal(true)
  }

  return (
    <Suspense
      fallback={
        <div className="w-full h-full flex items-center justify-center">
          <Loading className="w-20 h-20" />
        </div>
      }
    >
      <div className="absolute w-full h-full">
        <BgLeftCircle className="absolute left-[-453.4px] top-0" />
        <BgRightCircle className="absolute top-[-406px] right-[-394.95px]" />
      </div>
      <Router basename="">
        <AliveScope>
          <div className={`flex flex-col h-full relative overflow-x-hidden`}>
            <Header />
            <TxsUpdater />
            <FromWalletModal
              open={showFromWalletModal}
              onClose={handleCloseFromWalletModal}
              hideList={hideWalletList}
            />
            <div className={`mx-auto flex flex-1 flex-col justify-center pb-12 z-20 gap-[16px]`}>
              <WagmiConfig config={config}>
              <Web3Manager>
                <Switch>
                  <Route path="/" exact>
                    {(hideMarket || hideMarketManual) ? <Portal setHideWalletList={setHideWalletList} /> : <PortalWithMarket setHideWalletList={setHideWalletList} setHideMarketManul={() => setHideMarketManual(true)} />}
                  </Route>
                  <Route path="/pool" exact>
                    <PoolList setHideWalletList={setHideWalletList} />
                  </Route>
                  <Route path="/history" exact>
                    <div className="flex flex-col w-full mx-[32px] w-[327px] md:w-[704px] lg:w-[944px] xl:w-[1008px] bg-white rounded-[40px] overflow-y-auto bg-opacity-80 py-12 shadow-zg-1">
                      <History />
                    </div>
                  </Route>
                  <Route path="/pool/:id" exact>
                    <div className="flex flex-col w-full mx-[32px] w-[327px] md:w-[704px] lg:w-[944px] xl:w-[1008px] bg-white rounded-[40px] pt-0 overflow-y-auto bg-opacity-80 shadow-zg-1">
                      <PoolOperation openWalletModal={handleOpenFromWalletModal} setHideWalletList={setHideWalletList} />
                    </div>
                  </Route>
                  <Route path="/request" exact>
                    <div className="flex flex-col w-full mx-[32px] w-[327px] md:w-[640px] lg:w-[896px] xl:w-[1094px] pt-0 bg-opacity-80 pt-12">
                      <Request />
                    </div>
                  </Route>
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Web3Manager>
              </WagmiConfig>
              <div className={`text-gray-60 text-xs self-end pr-[32px] ${pathname === '/request' ? 'hidden' : ''}`}>
                Need help?{' '}
                <NavLink
                  className="underline text-A-700 cursor-pointer"
                  id="requst"
                  to={'/request'}
                >
                  Submit a request.
                </NavLink>
              </div>
            </div>
            <Footer />
          </div>
        </AliveScope>
      </Router>
    </Suspense>
  )
}

export default App
