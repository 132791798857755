/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import Big from 'big.js'
import {convertDecimal} from '@cfxjs/data-format'
import {useWallet, useWeb3Wallets} from '../hooks/useZGWallet'
import {
  UserOperationStatus,
  TransactionStatus,
  DepositStatus,
  TypeTransaction,
  ProxyUrlPrefix,
  KeyOfPortal,
  KeyOfCfx, findChainKeyById, KeyOfMetaMask,
  TxStatus,
} from '../constants'
import {requestUserOperationList} from '../utils/api'
import {useTxState} from '../state/transaction'
import {usePortalState} from '../state'
import {useAllTokenList} from '../hooks/useAllTokenList'
import {updateTx} from '../utils/index'
import {
  useTransactionNotification,
  // useClaimNotification,
} from '../pages/components'
import {useZGFormState} from '../state/zgForm'
import { requestDepositList } from '../utils/api'

function findTokenById(tokenList, id) {
  return tokenList.find(item => item.id === id)
  || tokenList.find(item => {
    const [chain, token] = id.split('+');
    return ((item.reference || '').toUpperCase() === token || (item.ctoken || '').toUpperCase() === token) && item.sfChain === chain;
  })
  || {}
}

function appendTxs(trans, txs) {
  txs.forEach(tx => {
    const hash = tx?.hash
    trans.set(hash, tx)
  })
}

export const useUpdateTxs = () => {
  const {fromWallet} = useZGFormState()
  const {address: cfxAddress, isCore} = useWallet(KeyOfPortal)
  const {web3Provider} = useWallet(fromWallet)
  const web3Wallets = useWeb3Wallets(fromWallet)
  const isCfxCore = cfxAddress && isCore
  const {transactions, setTransactions} = useTxState()
  const {txClaimModalShown} = usePortalState()
  const tokenList = useAllTokenList()
  const txNotificationShow = useTransactionNotification()

  window._transactions = new Map(Object.entries(transactions))
  useEffect(() => {
    const update = () => {
      let trans = new Map(window._transactions)
      let transArr = [...trans.values()]
      // only check fromAddress for pending
      // when tx type is approve transaction
      const approveTxs = transArr.filter(
        item => item.tx_type === TypeTransaction.approve,
      )
      const pendingApproveTxs = approveTxs.filter(
        item => item.status === TransactionStatus.pending,
      )
      const pendingInApproveTxs = pendingApproveTxs.filter(
        item => item.fromChain !== KeyOfCfx,
      )
      if (web3Provider) {
        pendingInApproveTxs.forEach(item => {
          const {hash} = item
          web3Provider.getTransactionReceipt(hash).then(res => {
            if (res?.status) {
              updateTx(trans, hash, {status: TransactionStatus.success})
            } else {
              updateTx(trans, hash, {status: TransactionStatus.error})
            }
          })
        })
      }

      const pendingOutApproveTxs = pendingApproveTxs.filter(
        item => item.fromChain === KeyOfCfx,
      )

      if (window?.confluxJS) {
        pendingOutApproveTxs.forEach(item => {
          const {hash} = item
          window.confluxJS.getTransactionReceipt(hash).then(res => {
            if (res?.outcomeStatus == 0) {
              updateTx(trans, hash, {status: TransactionStatus.success})
            } else {
              updateTx(trans, hash, {status: TransactionStatus.error})
            }
          })
        })
      }

      // check all connected address for history
      // when tx type is common transacton
      const commonTxs = transArr.filter(
        item => item.tx_type === TypeTransaction.transaction,
      )
      const pendingCommonTxs = commonTxs.filter(
        item =>
          item.status === TransactionStatus.pending ||
          item.status === TransactionStatus.waiting,
      )

      let requestTransactions = []
      if (isCfxCore) {
        requestTransactions.push(
          requestUserOperationList(
            ProxyUrlPrefix.shuttleflow,
            null,
            cfxAddress,
            Object.values(UserOperationStatus),
            null,
            null,
            1000,
          ).then(list => list.map(item => mapData(item, tokenList))),
        )
      }
      const isV2 = process.env.REACT_APP_ENABLE_V2 === 'true'
      if (web3Wallets.length && isV2) {
        requestTransactions = requestTransactions.concat(
          web3Wallets.map(account =>
            requestDepositList(ProxyUrlPrefix.zg, account, null, 0, 1000).then(
              list => list.map(item => mapDepositData(item, tokenList)),
            ),
          ),
        )
      }

      Promise.all(requestTransactions)
        .then(res => [].concat(...res))
        .then(list => {
          if (!list) return;
          const mappedData = _mapListToMap(list)

          // notification
          pendingCommonTxs.forEach(item => {
            const {hash, amount, fromChain, toChain, fromToken} = item
            const {display_symbol, symbol} = fromToken || {}
            const apiData = mappedData.get(hash)
            const {status: newStatus} = apiData || {}
            if (newStatus === TransactionStatus.success && !apiData?.response?.state2) { // if state2 exists, it means the transaction is refunded
              //Success Notification
              txNotificationShow({
                symbol: display_symbol || symbol,
                fromChain,
                toChain,
                value: amount,
              })
            }
          })

          appendTxs(trans, list)
          setTransactions(trans)
        })
        .finally(() => {})
    }
    if (isCfxCore || web3Wallets.length) {
      update()
    }
    let timeInterval
    if (isCfxCore || web3Wallets.length) {
      timeInterval = setInterval(() => update(), 10000)
    }
    return () => {
      timeInterval && clearInterval(timeInterval)
    }
  }, [
    cfxAddress,
    JSON.stringify(web3Wallets),
    txClaimModalShown,
    JSON.stringify(tokenList),
  ])

  function _mapListToMap(list) {
    const map = new Map()
    list.forEach(item => {
      if (item?.hash) {
        map.set(item.hash, item)
      }
    })
    return map
  }
}

/**
 * Get tokenInfo from tokenList by token address
 * Merge api data to local data
 */
export function mapData(item = {}, tokenList) {
  const data = {}
  if (!item) return {}
  const {
    from_chain,
    to_chain,
    token,
    status,
    timestamp,
    in_or_out,
    nonce_or_txid,
    to_addr,
    amount,
    tx_to,
    tx_input,
    user_addr,
    settled_tx,
  } = item
  // basic info
  data.response = item
  data.toAddress = to_addr
  data.tx_type = TypeTransaction.transaction
  data.hash = nonce_or_txid?.split('_')[0]
  data.toHash = settled_tx
  data.tx_to = tx_to
  data.tx_input = tx_input
  data.cfxAddress = user_addr
  data.timestamp = timestamp
  data.in_or_out = in_or_out

  // network
  if (in_or_out === 'in') {
    if (from_chain === KeyOfCfx) {
      data.fromChain = to_chain
      data.toChain = from_chain
    } else {
      data.fromChain = from_chain
      data.toChain = to_chain
    }
  }
  if (in_or_out === 'out') {
    if (from_chain === KeyOfCfx) {
      data.fromChain = from_chain
      data.toChain = to_chain
    } else {
      data.fromChain = to_chain
      data.toChain = from_chain
    }
  }

  // token
  data.fromToken = findTokenById(
    tokenList,
    `${data.fromChain}+${token.toUpperCase()}`,
  )
  data.toToken = findTokenById(
    tokenList,
    `${data.toChain}+${token.toUpperCase()}`,
  )
  data.amount = new Big(
    convertDecimal(BigInt(amount) || 0, 'divide', data.fromToken.decimals),
  ).toString()
  data.amountReceived = data.amount

  // map status
  data.status = TransactionStatus.pending
  if (status === 'confirming') {
    data.status = TransactionStatus.pending
  }
  if (status === 'doing') {
    if (tx_to && tx_input) {
      data.status = TransactionStatus.waiting
    } else {
      data.status = TransactionStatus.pending
    }
  }
  if (status === 'finished') {
    data.status = 'success'
  }
  if (status === 'invalid') {
    data.status = 'error'
  }

  return data
}

/**
 * Get tokenInfo from tokenList by token address
 * Merge api data to local data
 */
export function mapDepositData(item = {}, tokenList) {
  const data = {}
  if (!item) return {}
  const {
    src_chain_id,
    dest_chain_id,
    status,
    timestamp,
    depositor,
    // nonce,
    deposit_token,
    receiver,
    amount,
    receive_amount,
    receive_token,
    claim_tx_to,
    claim_tx_input,
    claim_tx_hash,
    deposit_tx_hash,
  } = item
  // basic info
  data.response = item
  data.timestamp = timestamp * 1000
  data.fromAddress = depositor
  data.toAddress = receiver
  data.tx_type = TypeTransaction.transaction
  data.hash = deposit_tx_hash
  data.toHash = claim_tx_hash
  // data.nonce = nonce

  // network
  const from_chain = findChainKeyById(src_chain_id)
  const to_chain = findChainKeyById(dest_chain_id)
  data.fromChain = from_chain
  data.toChain = to_chain

  // token
  data.fromToken = findTokenById(tokenList, `${src_chain_id}+${deposit_token}`)
  data.toToken = findTokenById(tokenList, `${dest_chain_id}+${receive_token}`)
  data.amount = new Big(
    convertDecimal(BigInt(amount) || 0, 'divide', data.fromToken?.decimals),
  ).toString()
  data.amountReceived = new Big(
    convertDecimal(
      BigInt(receive_amount) || 0,
      'divide',
      data.toToken?.decimals,
    ),
  ).toString()
  data.tx_to = claim_tx_to
  data.tx_input = claim_tx_input

  // map status
  data.status = TransactionStatus.pending
  if (status === DepositStatus.wait_for_claim) {
    data.status = TransactionStatus.waiting
  }
  if (status === DepositStatus.claimed) {
    data.status = TransactionStatus.success
  }
  if (status === DepositStatus.failed) {
    data.status = TransactionStatus.error
  }

  data.isZG = true

  return data
}

export const useTxData = (
  multipleOrderedStatus,
  transactionTypes = Object.values(TypeTransaction),
) => {
  const {transactions} = useTxState()
  const [arr, setArr] = useState([])
  const {address} = useWallet(KeyOfPortal)
  const web3Wallets = useWeb3Wallets()
  const connectedAddress = address
    ? [address].concat(web3Wallets)
    : [].concat(web3Wallets)

  useEffect(() => {
    if (connectedAddress.length) {
      const transArr = Object.values(transactions)
      const filteredTypeTxs = transArr.filter(
        tx =>
          transactionTypes.indexOf(tx.tx_type) != -1 &&
          multipleOrderedStatus.indexOf(tx.status) != -1,
      )
      const filteredTxs = filteredTypeTxs
        .filter(
          tx =>
            connectedAddress.indexOf((tx?.fromAddress || '').toLowerCase()) > -1 ||
            connectedAddress.indexOf((tx?.toAddress || '').toLowerCase()) > -1,
        )
        .sort((a, b) => b.timestamp - a.timestamp)

      setArr(filteredTxs)
    } else {
      setArr([])
    }
  }, [JSON.stringify(transactions), JSON.stringify(connectedAddress)])
  return arr
}

export const usePendingTransactions = () => {
    const pendingTransactions = useTxData(
      [TransactionStatus.pending, TransactionStatus.waiting],
      Object.values(TypeTransaction),
    );

    const pendingTransactionsIcon = pendingTransactions.length > 0 && (
      <div className="flex items-center justify-center w-4 h-4 absolute -top-1 -right-1 rounded-full bg-error text-xs text-white">
        {pendingTransactions.length > 99 ? '99+' : pendingTransactions.length}
      </div>
    )

    return [pendingTransactions, pendingTransactionsIcon];
}

export const useUpdateClaimedTxs = () => {
  const {claimedTxs, setClaimedTxs} = useTxState()
  const { fromWallet } = useZGFormState()
  const { web3Provider } = useWallet(fromWallet)
  window._claimedTxs = new Map(Object.entries(claimedTxs))
  useEffect(() => {
    const update = async () => {
      let trans = new Map(window._claimedTxs)
      let transArr = [...trans.values()]
      const submittedTrans = transArr.filter(
        item => item.status === TxStatus.submitted,
      )
      const mmSubmittedTrans = submittedTrans.filter(
        item => item.wallet === KeyOfMetaMask,
      )
      const portalSubmittedTrans = submittedTrans.filter(
        item => item.wallet === KeyOfPortal,
      )
      const mmArr = []
      if (web3Provider) {
        mmSubmittedTrans.forEach(item => {
          const {claimHash} = item
          mmArr.push(web3Provider.getTransactionReceipt(claimHash))
        })
      }
      const mmResults = await Promise.all(mmArr)
      mmResults.forEach((res, index) => {
        const {sendHash} = mmSubmittedTrans[index]
        if (res) {
          if (res?.status) {
            updateTx(trans, sendHash, {status: TxStatus.success})
          } else {
            updateTx(trans, sendHash, {status: TxStatus.error})
          }
        }
      })
      const confluxArr = []
      if (window?.confluxJS) {
        portalSubmittedTrans.forEach(item => {
          const {claimHash} = item
          confluxArr.push(window.confluxJS.getTransactionReceipt(claimHash))
        })
      }
      const confluxResults = await Promise.all(confluxArr)
      confluxResults.forEach((res, index) => {
        const {sendHash} = portalSubmittedTrans[index]
        if (res) {
          if (res?.outcomeStatus == 0) {
            updateTx(trans, sendHash, {status: TxStatus.success})
          } else {
            updateTx(trans, sendHash, {status: TxStatus.error})
          }
        }
      })
      setClaimedTxs(trans)
    }
    update()
    const timeInterval = setInterval(() => update(), 5000)
    return () => {
      timeInterval && clearInterval(timeInterval)
    }
  }, [web3Provider])
}
