import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {useLocation} from 'react-use'
import {useTranslation} from 'react-i18next'
import { useState } from 'react'

import { useIsMobile } from '../../../hooks'
import { HamburgerMenu, Close, Logo } from '../../../assets/svg'

import './header.css'

function Header() {
  const {t} = useTranslation()
  const {pathname} = useLocation()
  const isMobile = useIsMobile()

  const handleClickLogo = () => {
    window.open('https://0g.ai/', '_blank')
  }

  if (pathname === '/maintenance' || pathname === '/notfound') {
    return null
  }
  const disableNav = pathname === '/request'
  const isV2 = process.env.REACT_APP_ENABLE_V2 === 'true'
  return isMobile ? <MobileHeader disableNav={disableNav} /> : (
    <div className="mt-8 mb-5 px-8 bg-transparent flex justify-between items-center w-full">
      <button className='flex gap-[14px] items-center' onClick={handleClickLogo}>
        <img src={Logo} alt="logo" className="w-[192px] md:w-[192px] h-[30px]" />
      </button>
      {!disableNav && <div className="flex items-center justify-end w-full">
        <HeaderLink
          exact
          id="portal"
          to={'/'}
        >
          {t('nav.portal')}
        </HeaderLink>
        {isV2 && <HeaderLink
          id="pool"
          to={'/pool'}
        >
          {t('nav.pool')}
        </HeaderLink>}
        <HeaderLink
          id="history"
          to="/history"
          exact
        >
          {t('nav.history')}
        </HeaderLink>
      </div>}
    </div>
  )
}

Header.propTypes = {
  disableNav: PropTypes.bool,
}

function HeaderLink({to, children, disabled = false, ...props}) {
  const getStyle = () => {
    if (disabled) return 'text-gray-40'
    return 'text-gray-80'
  }
  return (
    <NavLink
      className={`text-base mr-9 h-6 flex items-center justify-center border-b-2 border-transparent text-G-900 ${getStyle()}`}
      to={to}
      {...props}
    >
      {children}
    </NavLink>
  )
}

HeaderLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
}

const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {t} = useTranslation()
  const {pathname} = useLocation()
  const disableNav = pathname === '/request'

  const handleClickLogo = () => {
    window.open('https://0g.ai/', '_blank')
  }
  const isV2 = process.env.REACT_APP_ENABLE_V2 === 'true'
  return menuOpen ? (
      <div className='h-full w-full bg-white absolute z-50'>
        <div className="mt-8 mb-5 px-8 bg-transparent w-full flex justify-end">
          <Close onClick={() => setMenuOpen(false)} />
        </div>
        <div className='ml-6'>
          <div className='mb-7'>
            <NavLink
              exact
              id="portal"
              to={'/'}
              onClick={() => setMenuOpen(false)}
            >
              {t('nav.portal')}
            </NavLink>
          </div>
          {isV2 && <div className='mb-7'>
            <NavLink
              id="pool"
              to={'/pool'}
              onClick={() => setMenuOpen(false)}
            >
              {t('nav.pool')}
            </NavLink>
          </div>}
          <div>
            <NavLink
              id="history"
              to="/history"
              exact
              onClick={() => setMenuOpen(false)}
            >
              {t('nav.history')}
            </NavLink>
          </div>
        </div>
      </div>
  ) : (
    <div className="mt-8 mb-5 px-8 bg-transparent w-full flex justify-between">
      <button className='flex gap-[14px] items-center' onClick={handleClickLogo}>
        <img src={Logo} alt="logo" className="w-[192px] md:w-[192px] h-[30px]" />
      </button>
      {!disableNav && <HamburgerMenu onClick={() => setMenuOpen(true)} />}
    </div>
  )
}

export default Header
